<template>
  <transition name="slide-fade" mode="out-in">
    <div class="LanguageSwitcher">
      <div class="LanguageSwitcher__label">
        <span>{{
          $store.getters.translations.chooseLanguage[
            this.$store.getters.language
          ]
        }}:</span>
      </div>
      <div class="LanguageSwitcher__icons-container">
        <img class="LanguageSwitcher__flag-icon" :class="currentLanguage === 'eng' ? 'active' : ''" src="@/assets/gb.png"
          @click="switchLanguage('eng')" />
        <img class="LanguageSwitcher__flag-icon" :class="currentLanguage === 'pl' ? 'active' : ''" src="@/assets/pl.png"
          @click="switchLanguage('pl')" />
      </div>
    </div>
  </transition>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  name: "LanguageSwitcher",
  computed: {
    currentLanguage() {
      return this.$store.getters.language;
    },
  },
  methods: {
    async switchLanguage(language) {
      if (this.currentLanguage !== language) {
        this.$cookie.setCookie("ServiceLanguage", language, { expire: '1m' });
        await this.$store.commit('switchLanguage', language);
        ElMessage({
          showClose: true,
          message: this.$store.getters.platformMessages.languageHasBeenChanged[this.$store.getters.language],
          type: 'success',
          duration: 1250,
        });
      }
    },
  },
};
</script>
<style scoped>
.LanguageSwitcher {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  background: #fff;
  border-radius: 5px;
  bottom: 5px;
  right: 5px;
  align-items: center;
  color: #000;
  font-size: 14px;
}

.LanguageSwitcher .LanguageSwitcher__icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.LanguageSwitcher .LanguageSwitcher__icons-container .LanguageSwitcher__flag-icon {
  width: 25px;
  cursor: pointer;
  margin: 5px 0;
  transition: all .2s ease-in;
}

.LanguageSwitcher .LanguageSwitcher__icons-container .LanguageSwitcher__flag-icon:hover {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .3);
}

.LanguageSwitcher .LanguageSwitcher__icons-container .active {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .3);
}

@media screen and (max-width: 769px) {
  .LanguageSwitcher {
    bottom: 5%;
  }
}
</style>


