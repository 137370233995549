import { VueCookieNext } from 'vue-cookie-next';

  VueCookieNext.config({
    expire: '1m',
    path: '/',
    domain: '',
    secure: '',
    sameSite: '',
  });

export default VueCookieNext;
