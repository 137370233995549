<template>
  <div class="Searchbar-container" :class="{ collapsed: isSearchbarCollpased }">
    <div class="Searchbar">
      <div class="Searchbar__header">
        <img class="search-icon" src="@/assets/search.svg" />
        <span class="search-label">{{
          $store.getters.translations.searchBy[this.$store.getters.language]
        }}:</span>
      </div>
      <div class="Searchbar__fields">
        <div class="Searchbar__row-first">
          <el-select v-model="selectedSearchVariantOption" placeholder="wybierz opcję">
            <el-option v-for="option in avaliableSearchVariantOptions" :key="option.value" :label="option.label"
              :value="option.value">
            </el-option>
          </el-select>
        </div>
        <div class="Searchbar__row-second">
          <transition name="slide-fade" mode="out-in">
            <el-input v-if="selectedSearchVariantOption === 'title'" v-model="selectedTitleForSearch" :placeholder="$store.getters.translations.enterTheTitle[
              this.$store.getters.language
            ]
              " clearable />
            <el-select v-else-if="selectedSearchVariantOption === 'groups'" v-model="selectedGroupsForSearch"
              :placeholder="$store.getters.translations.selectGroups[
                this.$store.getters.language
              ]
                " clearable>
              <el-option v-for="groups in avaliableGroupsForSelect" :key="groups" :label="groups" :value="groups">
              </el-option>
            </el-select>
            <el-select v-else-if="selectedSearchVariantOption === 'ecu'" v-model="selectedEcuForSearch" :placeholder="$store.getters.translations.selectEcu[
              this.$store.getters.language
            ]
              " clearable>
              <el-option v-for="ecu in avaliableEcusForSelect" :key="ecu" :label="ecu" :value="ecu">
              </el-option>
            </el-select>
            <el-select v-else-if="selectedSearchVariantOption === 'engine'" v-model="selectedEngineForSearch"
              :placeholder="$store.getters.translations.selectEngine[
                this.$store.getters.language
              ]
                " clearable>
              <el-option v-for="engine in avaliableEnginesForSelect" :key="engine" :label="engine" :value="engine">
              </el-option>
            </el-select>
            <div v-else-if="selectedSearchVariantOption === 'groupsAndEcu'">
              <el-select v-model="doubleFilters.groups" :placeholder="$store.getters.translations.selectGroups[
                this.$store.getters.language
              ]
                " clearable>
                <el-option v-for="groups in avaliableGroupsForSelect" :key="groups" :label="groups" :value="groups">
                </el-option>
              </el-select>
              <el-select style="margin-top: 5px" v-model="doubleFilters.ecu" :placeholder="$store.getters.translations.selectEcu[
                this.$store.getters.language
              ]
                " clearable>
                <el-option v-for="ecu in avaliableEcusForSelect" :key="ecu" :label="ecu" :value="ecu">
                </el-option>
              </el-select>
            </div>
          </transition>
        </div>
        <button class="clear-filters-button" @click="cleanFilters()" v-if="selectedTitleForSearch.length > 0 ||
          selectedGroupsForSearch.length > 0 ||
          selectedEcuForSearch.length > 0 ||
          selectedEngineForSearch.length > 0 ||
          doubleFilters.groups.length > 0 ||
          doubleFilters.ecu.length > 0
          ">
          {{
            $store.getters.translations.clearFilters[
              this.$store.getters.language
            ]
          }}
        </button>
      </div>
    </div>
    <transition name="slide-fade">
      <div class="warning-container" v-if="selectedTitleForSearch.length > 0 ||
          selectedGroupsForSearch.length > 0 ||
          selectedEcuForSearch.length > 0 ||
          selectedEngineForSearch.length > 0 ||
          doubleFilters.groups.length > 0 ||
          doubleFilters.ecu.length > 0
          ">
        <font-awesome-icon class="warning-icon" :icon="['fas', 'exclamation-triangle']" />
        <span class="warning-label">{{
          $store.getters.translations.containsActiveFilters[
          this.$store.getters.language
          ]
        }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Searchbar',

  props: {
    filesList: {
      type: Array,
      required: false,
    }
  },

  data() {
    return {
      isSearchbarCollpased: false,
      activeCollapse: [],
      avaliableSearchVariantOptions: [
        {
          label: this.$store.getters.translations.title[this.$store.getters.language],
          value: 'title',
        },
        {
          label: this.$store.getters.translations.groups[this.$store.getters.language],
          value: 'groups',
        },
        {
          label: 'ECU',
          value: 'ecu',
        },
        {
          label: this.$store.getters.translations.engine[this.$store.getters.language],
          value: 'engine',
        },
        {
          label: this.$store.getters.translations.groups[this.$store.getters.language] + ' + ECU',
          value: 'groupsAndEcu',
        }
      ],
      avaliableGroupsForSelect: [],
      avaliableEcusForSelect: [],
      avaliableEnginesForSelect: [],
      selectedSearchVariantOption: 'title',
      selectedTitleForSearch: '',
      selectedGroupsForSearch: '',
      selectedEcuForSearch: '',
      selectedEngineForSearch: '',
      doubleFilters: {
        groups: '',
        ecu: '',
      }
    }
  },

  watch: {
    filesList(newValue) {
      let groupsArr = [];
      let ecusArr = [];
      let enginesArr = [];
      newValue.forEach(file => {
        if (!groupsArr.includes(file.groups) && !!file.groups) {
          groupsArr.push(file.groups);
        }
        if (!ecusArr.includes(file.ecu) && !!file.ecu) {
          ecusArr.push(file.ecu);
        }
        if (!enginesArr.includes(file.engine) && !!file.engine) {
          enginesArr.push(file.engine);
        }
      });
      this.avaliableGroupsForSelect = groupsArr;
      this.avaliableEcusForSelect = ecusArr;
      this.avaliableEnginesForSelect = enginesArr;
    },
    selectedSearchVariantOption(newValue) {
      if (newValue === 'title') {
        this.selectedGroupsForSearch = '';
        this.selectedEcuForSearch = '';
        this.selectedEngineForSearch = '';
        this.doubleFilters = {
          groups: '',
          ecu: '',
        }
      } else if (newValue === 'groups') {
        this.selectedTitleForSearch = '';
        this.selectedEcuForSearch = '';
        this.selectedEngineForSearch = '';
        this.doubleFilters = {
          groups: '',
          ecu: '',
        }
      } else if (newValue === 'ecu') {
        this.selectedTitleForSearch = '';
        this.selectedGroupsForSearch = '';
        this.selectedEngineForSearch = '';
        this.doubleFilters = {
          groups: '',
          ecu: '',
        }
      } else if (newValue === 'engine') {
        this.selectedTitleForSearch = '';
        this.selectedGroupsForSearch = '';
        this.selectedEcuForSearch = '';
        this.doubleFilters = {
          groups: '',
          ecu: '',
        }
      } else if (newValue === 'groupsAndEcu') {
        this.selectedTitleForSearch = '';
        this.selectedGroupsForSearch = '';
        this.selectedEcuForSearch = '';
        this.selectedEngineForSearch = '';
      }
    },

    selectedTitleForSearch(newValue) {
      const params = {
        type: 'title',
        value: newValue,
      };
      this.$emit('filtering-files-on-change', params)
    },

    selectedGroupsForSearch(newValue) {
      const params = {
        type: 'groups',
        value: newValue,
      };
      this.$emit('filtering-files-on-change', params)
    },

    selectedEcuForSearch(newValue) {
      const params = {
        type: 'ecu',
        value: newValue,
      };
      this.$emit('filtering-files-on-change', params)
    },

    selectedEngineForSearch(newValue) {
      const params = {
        type: 'engine',
        value: newValue,
      };
      this.$emit('filtering-files-on-change', params)
    },

    'doubleFilters.groups'() {
      const params = {
        type: 'groupsAndEcu',
        value: this.doubleFilters,
      };
      this.$emit('filtering-files-on-change', params)
    },

    'doubleFilters.ecu'() {
      const params = {
        type: 'groupsAndEcu',
        value: this.doubleFilters,
      };
      this.$emit('filtering-files-on-change', params)
    }
  },

  methods: {
    cleanFilters() {
      this.selectedGroupsForSearch = '';
      this.selectedTitleForSearch = '';
      this.selectedEcuForSearch = '';
      this.selectedEngineForSearch = '';
      this.doubleFilters = {
        groups: '',
        ecu: '',
      }
      this.activeCollapse = [];
      this.$emit('clear-filters');
    },
  }
}
</script>
<style>
.Searchbar-container {
  display: flex;
  flex-direction: row;
  transition: all .3s ease-in;
  width: 350px;
}

.Searchbar-container .Searchbar {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.Searchbar-container .Searchbar .Searchbar__fields {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}

.Searchbar-container .Searchbar .Searchbar__fields .el-input__inner {
  border: none;
  color: #fff;
}

.Searchbar-container .Searchbar .Searchbar__fields .el-input__wrapper {
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: none;
  background: transparent;
}

.Searchbar-container .Searchbar .Searchbar__row-first,
.Searchbar-container .Searchbar .Searchbar__row-second {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.Searchbar-container .Searchbar .el-select .el-select__wrapper {
  background: transparent;
  color: #fff;
  width: 140px;
}

.Searchbar-container .Searchbar .Searchbar__row-second .el-input__wrapper {
  background: transparent;
  color: #fff;
}

.Searchbar-container .Searchbar .Searchbar__row-second .el-select__wrapper {
  width: 200px;
}

.Searchbar-container .Searchbar .Searchbar__fields .el-select__placeholder {
  color: #fff;
}

.Searchbar-container .warning-container {
  margin: auto;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 20%;
  background: #000;
  padding: 10px;
}

.Searchbar-container .warning-icon {
  font-size: 24px;
  color: rgb(255, 208, 0);
  margin-left: auto;
  margin-right: 10px;
}

.Searchbar-container .Searchbar__header {
  font-size: 20px;
  color: #42C7C7;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 7px;
}

.Searchbar-container .Searchbar__header .search-icon {
  margin-right: 10px;
  font-size: 20px;
}

.Searchbar .clear-filters-button {
  align-self: center;
  background-color: rgb(223, 0, 0);
  color: #fff;
  padding: 5px 10px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .1s ease;
  border-radius: 5px;
  margin: 5px 0;
}

@media screen and (max-width: 769px) {
  .Searchbar-container {
    width: 100%;
  }

  .Searchbar-container .Searchbar {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }

  .Searchbar-container .Searchbar__fields,
  .Searchbar-container .Searchbar .Searchbar__row-second {
    width: 100%;
  }
}
</style>