<template>
  <div class="ResetPassword">
    <StartPageHeader />
    <BackButtonFixed
      :icon="'arrow-left'"
      @back="back"
      :backgroundColor="'#000000'"
    />
    <div class="ResetPassword__content">
      <h2>
        {{
          $store.getters.translations.enterNewPassword[$store.getters.language]
        }}
      </h2>
      <el-form
        ref="resetPasswordFormRef"
        :model="resetPasswordForm"
        status-icon
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="
            $store.getters.translations.repeatEmail[$store.getters.language]
          "
          prop="email"
        >
          <el-input
            v-model="resetPasswordForm.email"
            type="text"
            autocomplete="on"
          />
        </el-form-item>
        <el-form-item
          :label="$store.getters.translations.password[$store.getters.language]"
          prop="password"
        >
          <el-input
            v-model="resetPasswordForm.password"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item
          :label="
            $store.getters.translations.repeatPassword[$store.getters.language]
          "
          prop="checkPassword"
        >
          <el-input
            v-model="resetPasswordForm.checkPassword"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item>
          <el-button size="large" type="primary" @click="submitForm(resetPasswordFormRef)">{{
            $store.getters.translations.save[$store.getters.language]
          }}</el-button>
          <el-button size="large" @click="resetForm(resetPasswordFormRef)">{{
            $store.getters.translations.clear[$store.getters.language]
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <StartPageFooter />
  </div>
</template>

<script>
import StartPageHeader from "@/components/Platform/StartPageHeader.vue";
import StartPageFooter from "@/components/Platform/StartPageFooter.vue";
import BackButtonFixed from "@/components/Platform/BackButtonFixed.vue";
import { ElMessage } from "element-plus";

export default {
  name: "About",
  components: {
    StartPageHeader,
    StartPageFooter,
    BackButtonFixed,
  },

  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$store.getters.translations.emailRequired[this.$store.getters.language]));
      } else {
        if (!this.validateEmail()) {
          callback(new Error(this.$store.getters.platformMessages.invalidEmail[this.$store.getters.language]));
        } else {
          callback();
        }
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$store.getters.translations.passwordRequired[this.$store.getters.language]));
      } else {
        if (value.length < 5) {
          callback(new Error(this.$store.getters.translations.passwordMustBe[this.$store.getters.language]));
        }
        if (this.resetPasswordForm.checkPassword !== "") {
          this.$refs.resetPasswordFormRef.validateField("checkPassword");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$store.getters.translations.repeatPassword[this.$store.getters.language]));
      } else if (value !== this.resetPasswordForm.password) {
        callback(new Error(this.$store.getters.translations.passwordNotMatch[this.$store.getters.language]));
      } else {
        callback();
      }
    };
    return {
      resetPasswordForm: {
        email: "",
        password: "",
        checkPassword: "",
        token: this.$route.params.token,
      },
      rules: {
        email: [{ validator: validateEmail, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },

  methods: {
    back() {
      let isLogged = this.$store.getters.isLogged;
      if (isLogged) {
        this.$router.push({ name: "UserFiles" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    async submitForm() {
      const result = await this.$store.dispatch(
        "updatePassword",
        this.resetPasswordForm
      );
      if (result.status === 201 || result.status === 200) {
        ElMessage({
          showClose: true,
          message:
            this.$store.getters.platformMessages.passwordUpdated[
              this.$store.getters.language
            ],
          duration: 3500,
          type: "success",
        });
        this.$router.push({ name: "Home" });
      }
    },
    resetForm() {
      this.resetPasswordForm = {
        email: "",
        password: "",
        checkPassword: "",
        token: this.$route.params.token,
      };
    },
    validateEmail() {
      const rule =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return rule.test(this.resetPasswordForm.email);
    },
  },
};
</script>

<style>
.ResetPassword {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #2f2f30;
  min-height: 100vh;
}

.ResetPassword__content {
  padding: 0 3%;
  text-align: left;
  font-size: 18px;
}

.ResetPassword__content h2 {
  font-size: 700;
}

.ResetPassword__content .el-form .el-form-item__content {
  display: flex;
  justify-content: flex-end;
}

.ResetPassword__content .el-form .el-form-item__label {
  color: #fff;
  width: 210px !important;
}

@media screen and (max-width: 769px) {
  .ResetPassword__content .el-form .el-form-item {
    flex-direction: column;
  }

  .ResetPassword__content .el-form .el-form-item__label {
    text-align: left;
  }
}
</style>
