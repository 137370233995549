<template>
  <div class="PasswordResetModal__secondary-bg">
    <div class="PasswordResetModal">
      <font-awesome-icon class="PasswordResetModal__close-icon" :icon="['fas', 'times']"
        @click="openOrCloseModal(false)" />
      <p>
        {{
          $store.getters.translations.enterEmail[this.$store.getters.language]
        }}:
      </p>
      <el-form :model="enterEmailForm" status-icon :rules="rules" ref="enterEmailForm" label-width="200px"
        class="PasswordResetModal__form" label-position="top">
        <el-form-item label="Email" prop="email" required>
          <el-input v-model="enterEmailForm.email"></el-input>
        </el-form-item>

        <vue-recaptcha v-if="showRecaptcha" sitekey="6LcabtgcAAAAAPbtN31PN6KSxL99pQBgx3shezgD" size="normal" theme="light"
          :tabindex="0" @verify="recaptchaVerified" @expire="recaptchaExpired" @fail="recaptchaFailed" ref="vueRecaptcha">
        </vue-recaptcha>

        <el-form-item>
          <el-button size="large" type="primary" style="margin-top: 20px" @click="sendPasswordResetLink()" :disabled="!isVerifiedCaptcha ||
            enterEmailForm.email.length === 0 ||
            !this.isEmailValidated
            " :loading="isLinkSending">{{
    $store.getters.translations.sendRestorePasswordLink[
    this.$store.getters.language
    ]
  }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';
import { ElMessage } from 'element-plus';

export default {
  name: "RegisPasswordResetModalterModal",

  components: {
    vueRecaptcha
  },

  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$store.getters.translations.emailRequired[this.$store.getters.language]));
      } else {
        if (!this.validateEmail()) {
          this.isEmailValidated = false;
          callback(new Error(this.$store.getters.platformMessages.invalidEmail[this.$store.getters.language]));
        } else {
          this.isEmailValidated = true;
          callback();
        }
      }
    };
    return {
      showRecaptcha: true,
      isVerifiedCaptcha: false,
      isEmailValidated: false,
      isLinkSending: false,
      enterEmailForm: {
        email: '',
      },
      rules: {
        email: [
          {
            validator: validateEmail,
            trigger: 'blur',
          }
        ],
      },
    }
  },

  methods: {
    openOrCloseModal() {
      this.$emit('openCloseModal', false);
    },
    recaptchaVerified(response) {
      if (response) {
        this.isVerifiedCaptcha = true;
      }
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      ElMessage({
        showClose: true,
        message: 'Recaptcha failed',
        type: 'error',
      });
    },
    async sendPasswordResetLink() {
      this.enterEmailForm.email = this.enterEmailForm.email.trim();
      //sprawdza prawidłowośc adresu email, jestli jest ok, wykonuje akcje rejestracji
      if (this.validateEmail()) {
        this.isLinkSending = true;
        const result = await this.$store.dispatch('resetPassword', this.enterEmailForm.email);
        if (result.status === 200 || result.status === 201) {
          ElMessage({
            showClose: true,
            message: this.$store.getters.platformMessages.passwordResetLinkSent[this.$store.getters.language],
            duration: 6000,
            type: 'success',
          });
        }
        this.isLinkSending = false;
        this.openOrCloseModal(false);
      } else {
        ElMessage({
          showClose: true,
          message: this.$store.getters.platformMessages.invalidEmail[this.$store.getters.language],
          type: 'error',
        });
      }
    },

    validateEmail() {
      const rule = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return rule.test(this.enterEmailForm.email);
    },

    validateCheckbox(rule, value, callback) {
      if (value !== true) {
        callback(new Error(`${this.$store.getters.translations.acceptanceTerms[this.$store.getters.language]}`));
      }
    },

    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      await this.$recaptcha('register');
      // Do stuff with the received token.
    },
    showTerms() {
      this.isTermsVisible = true;
    }
  }
}
</script>
<style>
.PasswordResetModal__secondary-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.PasswordResetModal {
  padding: 20px 0;
  position: fixed;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  background-color: #333333;
}

.PasswordResetModal .PasswordResetModal__close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.PasswordResetModal .PasswordResetModal__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PasswordResetModal .PasswordResetModal__form .el-form-item__label {
  color: #fff;
  padding: 0;
}

.PasswordResetModal .PasswordResetModal__form .el-form-item {
  width: 100%;
}

@media screen and (max-width: 769px) {
  .PasswordResetModal__secondary-bg .el-dialog {
    min-width: 90%;
    margin-top: 15vh !important;
  }

  .PasswordResetModal {
    max-width: 100;
    width: auto;
    padding: 20px 25px;
  }
}
</style>


