export default {
    title: {
        pl: 'Tytuł',
        eng: 'Title',
    },
    groups: {
        pl: 'Grupy',
        eng: 'Groups',
    },
    comment: {
        pl: 'Komentarz',
        eng: 'Comment'
    },
    createdAt: {
        pl: 'Utworzono',
        eng: 'Created at'
    },
    yes: {
        pl: 'Tak',
        eng: 'Yes',
    },
    no: {
        pl: 'Nie',
        eng: 'No',
    },
    enterTitle: {
        pl: 'Wpisz tytuł pliku',
        eng: 'Enter a title for the file',
    },
    enterComment: {
        pl: 'Wpisz komentarz',
        eng: 'Please enter a comment',
    },
    enterFolderName: {
        pl: 'Wprowadź nazwę folderu',
        eng: 'Enter folder name',
    },
    save: {
        pl: 'Zapisz',
        eng: 'Save',
    },
    cancel: {
        pl: 'Anuluj',
        eng: 'Cancel'
    },
    add: {
        pl: 'Dodaj',
        eng: 'Add'
    },
    close: {
        pl: 'Zamknij',
        eng: 'Close',
    },
    select: {
        pl: 'Wybierz',
        eng: 'Select',
    },
    allFiles: {
        pl: 'Wszystkie pliki',
        eng: 'All files'
    },
    folders: {
        pl: 'Foldery',
        eng: 'Folders'
    },
    selectTheFiles: {
        pl: 'Wybierz pliki, które chcesz dodać do folderu',
        eng: 'Select the files you want to add to the folder',
    },
    uploadFiles: {
        pl: 'Prześlij pliki',
        eng: 'Upload files '
    },
    createFolder: {
        pl: 'Dodaj folder',
        eng: 'Create a folder'
    },
    addFiles: {
        pl: 'Dodaj pliki',
        eng: 'Add files'
    },
    searchBy: {
        pl: 'Szukaj po',
        eng: 'Search by',
    },
    sortByData: {
        pl: 'Sortuj po dacie',
        eng: 'Sort by data',
    },
    enterTheTitle: {
        pl: 'Wpisz tytuł',
        eng: 'Enter the title',
    },
    selectGroups: {
        pl: 'Wybierz grupy',
        eng: 'Select groups',
    },
    selectEngine: {
        pl: 'Wybierz silnik',
        eng: 'Select engine',
    },
    selectEcu: {
        pl: 'Wybierz ECU',
        eng: 'Select ECU',
    },
    clearFilters: {
        pl: 'Wyczyść filtry',
        eng: 'Clear filters',
    },
    containsActiveFilters: {
        pl: 'Zawiera aktywne filtry',
        eng: 'Contains active filters',
    },
    fromTheOldest: {
        pl: 'Od najstarszych',
        eng: 'From the oldest',
    },
    fromTheNewest: {
        pl: 'Od najnowszych',
        eng: 'From the newest',
    },
    whatAreLogs: {
        pl: 'Czym są logi',
        eng: 'What are logs',
    },
    name: {
        pl: 'Nazwa użytkownika',
        eng: 'Name',
    },
    nameRequired: {
        pl: 'Nazwa użytkownika jest wymagana',
        eng: 'Username is required',
    },
    nameMustContain: {
        pl: 'Nazwa użytkownika musi zawierać co najmniej 2 znaki',
        eng: 'Username must contain at least 2 characters',
    },
    password: {
        pl: 'Hasło',
        eng: 'Password',
    },
    passwordRequired: {
        pl: 'Hasło jest wymagane',
        eng: 'Password is required',
    },
    repeatPassword: {
        pl: 'Powtórz hasło',
        eng: 'Repeat password',
    },
    repeatEmail: {
        pl: 'Wpisz ponownie swój email',
        eng: 'Repeat your email adress',
    },
    passwordMustBe: {
        pl: 'Hasło musi się składać z co najmniej 5 znaków',
        eng: 'The password must be at least 5 characters long',
    },
    emailRequired: {
        pl: 'Adres email jest wymagany',
        eng: 'Email address is required'
    },
    show: {
        pl: 'Wyświetl',
        eng: 'Show',
    },
    showChart: {
        pl: 'Wyświetl w postaci wykresu',
        eng: 'View as a graph',
    },
    showTable: {
        pl: 'Wyświetl w postaci tabeli',
        eng: 'View as a table',
    },
    primary: {
        pl: 'Główny',
        eng: 'Primary',
    },
    secondary: {
        pl: 'Dodatkowy',
        eng: 'Secondary'
    },
    areYouSure: {
        pl: 'Czy na pewno chcesz usunąć wybrany element',
        eng: 'Are you sure you want to delete the selected item'
    },

    edit: {
        pl: 'Edytuj',
        eng: 'Edit',
    },
    share: {
        pl: 'Udostępnij',
        eng: 'Share',
    },
    shareLinkBelow: {
        pl: 'W celu udostępnienia logu, skopiuj poniższy link:',
        eng: 'To share the log, copy the link below:',
    },
    browse: {
        pl: 'Przeglądaj',
        eng: 'Browse',
    },
    dragger: {
        dragFileHere: {
            pl: 'Przeciągnij plik tutaj lub',
            eng: 'Drag the file here or',
        },
        clickViaSelectFile: {
            pl: 'kliknij by wybrać plik z dysku',
            eng: 'click via select a file from disk',
        },
    },
    logout: {
        pl: 'Wyloguj',
        eng: 'Logout',
    },
    terms: {
        iAccept: {
            pl: 'Akceptuję',
            eng: 'I accept',
        },
        theTerms: {
            pl: 'regulamin',
            eng: 'the terms',
        },
        ofWebiste: {
            pl: 'strony',
            eng: 'of webiste',
        }
    },
    register: {
        pl: 'Rejestracja',
        eng: 'Sign up',
    },
    termsModal: {
        termsOfService: {
            pl: 'Regulamin serwisu vaglogs.com',
            eng: 'Terms of service vaglogs.com'
        },
        termsList: [
            {
                pl: 'Korzystanie z serwisu VAGLOGS.COM jest bezpłatne.',
                eng: 'The use of VAGLOGS.COM is free of charge.',
            },
            {
                pl: 'Serwis zastrzega sobie możliwość usunięcia plików z serwera bez podawania powodu.',
                eng: 'The website reserves the right to delete files from the server without giving any reason.',
            },
            {
                pl: 'Serwis VAGLOGS.COM nie ponosi odpowiedzialności za przesłane przez użytkownika pliki.',
                eng: 'VAGLOGS.COM is not responsible for the files sent by the user.',
            },
            {
                pl: 'Akceptowane są wyłącznie pliki CSV z programów VAG-COM oraz VCDS.',
                eng: 'Only CSV files from VAG-COM and VCDS programs are accepted.',
            },
            {
                pl: 'Strona używa plików Cookie do trzymania sesji użytkownika oraz w celu przechowywania informaci o wybranym języku strony.',
                eng: "The website uses cookie files to keep the user's session and to store information about the selected language of the website.",
            },
            {
                pl: 'Do zarejestrowania konta wymagane jest wyrażenie zgody na przetwarzanie danych osobowych oraz otrzymywanie informacji handlowej na podany podczas rejestracji adres e-mail. Dane te będą przetwarzane zgodnie z Ustawą o ochronie danych osobowych z dnia z 29 sierpnia 1997 r. (tekst jednolity: Dz. U. 02.101.926 ze zm.) w celach administracyjnych, statystycznych i marketingowych (w tym w celach reklamowych).',
                eng: 'Registering an account requires your approval for processing your personal information, according to the Polish law (Ustawa o ochronie danych osobowych z dnia z 29 sierpnia 1997 r. (tekst jednolity: Dz. U. 02.101.926 ze zm.)), for administrative and marketing purporses.',
            },
            {
                pl: 'Ważny od 26.12.2021',
                eng: 'Valid from December 26, 2021',
            }
        ],
    },
    acceptanceTerms: {
        pl: 'Akceptacja regulaminu jest wymagana',
        eng: 'Acceptance of the terms is required',
    },
    createAnAccount: {
        pl: 'Załóż konto aby mieć więcej możliwości!',
        eng: 'Create an account to have more options!'
    },
    whenYouCreated: {
        pl: 'Po założeniu konta możesz przechowywać przesłane przez siebie logi, wyświetlać je również w formie wykresów oraz udostępniać innym osobom.',
        eng: 'After creating an account, you can store the logs you sent, also display them in the form of charts and share them with other people.',
    },
    aboutVaglogs: {
        pl: 'VAGLOGS to narzędzie do wyświetlania (również w formie wykresu), przechowywania i udostępniania logów VCDS/VAG-COM.',
        eng: 'VAGLOGS is a tool for displaying (also in a chart), storing, managing and sharing VCDS / VAG-COM logs.',
    },
    aboutButton: {
        pl: 'Czym jest',
        eng: 'About',
    },
    noLogs: {
        pl: 'Brak logów do wyświetlenia',
        eng: 'No logs to display',
    },
    addLogsToView: {
        pl: 'Dodaj logi do wyświetlenia',
        eng: 'Add logs to view',
    },
    signIn: {
        pl: 'Zaloguj',
        eng: 'Sign in',
    },
    enterEmail: {
        pl: 'Wprowadź adres e-mail',
        eng: 'Enter your e-mail address',
    },
    enterPassword: {
        pl: 'Wprowadź hasło',
        eng: 'Enter your password',
    },
    selectChartModel: {
        pl: 'Wybierz model wykresu',
        eng: 'Select the chart model',
    },
    numberYAxes: {
        pl: 'Ilość osi Y',
        eng: 'Number of Y axes'
    },
    date: {
        pl: 'Data',
        eng: 'Date',
    },
    engine: {
        pl: 'Silnik',
        eng: 'Engine',
    },
    aboutCookies: {
        pl: 'Informacje o ciasteczkach',
        eng: 'Information about cookies',
    },
    serviceUsingCookies: {
        pl: 'Ta strona wykorzystuje pliki cookies w celu świadczenia najwyższej jakości usług. Pozostając na stronie wyrażasz zgodę na wykorzystanie plików cookies.',
        eng: 'This website uses cookies to provide the highest quality services. By staying on the website, you consent to the use of cookies.',
    },
    hint: {
        pl: 'Porada',
        eng: 'Hint',
    },
    hideValuesOnChart: {
        pl: 'Możesz ukrywać wybrane wartości na wykresie, klikając w nie na legendzie wykresu',
        eng: 'You can hide selected values ​​in the chart by clicking on them in the chart legend',
    },
    logsAreFilesToAnalyze: {
        pl: 'Logi są to pliki, generowane przez program diagnostyczny, służące do analizy pracy poszczególnych komponentów samochodu. Na podstawie analizy logów, jesteśmy w stanie diagnozować usterki oraz kontrolować poszczególne wartości, podczas podnoszenia mocy w samochodzie. W celu wygenerowanie logu, należy połączyć komputer do auta za pomocą złącza OBD2 oraz uruchomić dedykowany do tego program VAG.',
        eng: 'Logs are files generated by a diagnostic program and used to analyze the operation of individual car components. Based on the log analysis, we are able to diagnose faults and control individual values ​​while increasing the power in the car. In order to generate a log, connect the computer to the car via the OBD2 connector and run the dedicated VAG program.'
    },
    moreUsefulInfoBelow: {
        pl: 'Więcej przydatnych informacji poniżej',
        eng: 'More useful information below',
    },
    haveNotYouAccount: {
        pl: 'Nie masz konta',
        eng: 'No account',
    },
    forgotPassword: {
        pl: 'Zapomniałem hasła',
        eng: 'Forgot my password',
    },
    sendRestorePasswordLink: {
        pl: 'Wyślij link resetujący hasło',
        eng: 'Send reset password link',
    },
    chooseLanguage: {
        pl: 'Wybierz język',
        eng: 'Choose language',
    },
    enterNewPassword: {
        pl: 'Wprowadź nowe hasło',
        eng: 'Enter new password',
    },
    clear: {
        pl: 'Wyczyść',
        eng: 'Clear',
    },
    passwordNotMatch: {
        pl: 'Hasło nie pasuje',
        eng: 'Password does not match',
    },
    emptyFolder: {
        pl: 'Pusty folder',
        eng: 'Empty folder'
    },
    copyToClipboard: {
        pl: 'Kopiuj do schowka',
        eng: 'Copy to clipboard'
    }
}