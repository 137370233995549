<template>
  <div class="Sort">
    <font-awesome-icon class="sort-icon" :icon="['fas', 'sort-alpha-up']" />
    <span class="sort-label">{{
      $store.getters.translations.sortByData[this.$store.getters.language]
    }}:</span>
    <el-select v-model="currentSortOrderOption" placeholder="sortuj od:">
      <el-option v-for="orderOption in sortOrderOptions" :key="orderOption.value" :label="orderOption.label"
        :value="orderOption.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'Sort',

  props: {
    filesList: {
      type: Array,
      required: false,
    }
  },

  data() {
    return {
      currentSortOption: this.$store.getters.currentSortOption,
      currentSortOrderOption: this.$store.getters.currentSortOrderOption,
      sortOptions: [
        {
          value: 'data',
          label: this.$store.getters.translations.date[this.$store.getters.language],
        },
      ],
      sortOrderOptions: [
        {
          value: 'asc',
          label: this.$store.getters.translations.fromTheOldest[this.$store.getters.language],
        },
        {
          value: 'desc',
          label: this.$store.getters.translations.fromTheNewest[this.$store.getters.language],
        },
      ]
    }
  },

  watch: {
    currentSortOrderOption(newValue) {
      this.$store.dispatch('sortList', newValue);
    }
  },
}
</script>
<style>
.Sort {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  width: 50%;
  font-size: 14px;
}

.Sort .sort-icon {
  margin-right: 10px;
  font-size: 20px;
}

.Sort .sort-label {
  margin-right: 15px;
}

.Sort .el-select__wrapper {
  box-shadow: none;
  background: transparent;
}

.Sort .el-select__placeholder {
  color: #fff;
}

@media screen and (max-width: 600px) {
  .Sort {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 700px) {
  .Sort {
    order: -1;
    margin-left: 10px;
    margin-right: auto;
    min-width: 270px;
  }
}
</style>