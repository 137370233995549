<template>
  <div class="Foundation">
    <div class="Foundation__header">
      <img src="https://vaglogs.com/img/foundation.jpg" class="Foundation__header_logo" />
      <div class="Foundation__header_content">
        <h1>Fundacja Pomocy Dzieciom z Chorobą Nowotworową</h1>
        <p class="bolded">KRS 0000079660</p>
      </div>
    </div>
    <div class="Foundation__content">
      <p>Od 33 lat wspieramy dzieci chore onkologicznie i ich rodziny.</p>
      <p>Wpływy uzyskane z 1,5% podatku wykorzystujemy na realizację programów skierowanych bezpośrednio do
        podopiecznych fundacji, przede wszystkim na udoskonalanie naszej głównej działalności, jaką jest prowadzenie
        Ośrodka Terapeutycznego z miejscami hotelowymi.</p>
      <p class="bolded">Podaj rękę dziecku choremu na nowotwór!</p>
      <span class="bolded">Przekaż nam</span>
      <div style="display: inline-block;">
        <span class="awarded first-block" style="margin-left: 10px">1,5</span><span
          class="awarded second-block">%</span>
      </div>
    </div>
    <div class="Foundation__footer">
      <span class="Foundation__footer_text">Fundacja Pomocy Dzieciom z Chorobą Nowotworową</span>
      <span class="Foundation__footer_text">KRS 0000079660</span>
      <span class="Foundation__footer_text">ul. Przybyszewskiego 47, 01-849 Warszawa</span>
      <span class="Foundation__footer_text">fundacja.net</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Foundation',

  methods: {
    closeCookiebar() {
      this.$emit('closeCookiebar');
    }
  }
}

</script>
<style scoped>
@media screen and (max-width: 500px) {
  .Foundation__header {
    flex-direction: column !important;
  }

  .Foundation__header_logo {
    margin: auto !important;
    transform: scale(1);
  }
}

@media screen and (max-width: 1170px) {
  .Foundation__header {
    width: 100% !important;

  }

  .Foundation__header h1 {
    font-size: 20px;
  }

  .Foundation__header_logo {
    transform: scale(.8);
  }

  .Foundation__content {
    width: 100% !important;
  }

  .Foundation__footer_wave {
    display: none;
  }

  .bolded {
    font-size: 24px !important;
  }

  .awarded {
    font-size: 24px !important;
  }

  .first-block,
  .second-block {
    border-width: 3px !important;
  }
}

.Foundation__header {
  margin: auto;
  width: 70%;
  display: flex;
  flex-direction: row;
}

.Foundation__header_logo {
  max-width: 190px;
  width: 190px;
  margin-right: 15px;
}

.Foundation__header h1 {
  color: rgb(33, 59, 152);
}

.Foundation__content {
  width: 45%;
  margin: auto;
  font-size: 16px;
  line-height: 1.5;
  color: rgb(0, 60, 190);
}

.Foundation__footer {
  color: 000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
}

.Foundation__footer_text {
  display: inline-block;
}

.Foundation__footer_wave {
  position: absolute;
  top: 0;
  width: 100%;
}

.bolded {
  color: rgb(128, 2, 2);
  font-size: 32px;
  font-weight: bold;
}

.awarded {
  color: rgb(255, 136, 0);
  font-size: 42px;
  font-weight: bold;
}

.first-block {
  border: 5px solid rgb(255, 136, 0);
  border-radius: 10px 0 0 10px;
  display: inline-block;
  padding: 0 5px;
}

.second-block {
  border: 5px solid rgb(255, 136, 0);
  border-left: 0;
  border-radius: 0 10px 10px 0;
  display: inline-block;
  padding: 0 5px;
}
</style>