import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import UserFiles from '../views/UserFiles.vue';
import ExternalFileResult from '../views/ExternalFileResultsView.vue';
import LocalExternalFileResults from '../views/LocalExternalFileResultsView.vue';
import ResetPassword from '../views/ResetPasswordView.vue';
import VueCookieNext from '../cookies/config';
import { API, addBearerToken } from '@/config/API.js';

import store from '../store';

const routes = [
  {
    path: '/:confirmation_status?',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: false },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { requiresAuth: false },
  },
  {
    path: '/files',
    name: 'UserFiles',
    component: UserFiles,
    meta: { requiresAuth: true },
  },
  {
    path: '/log/local',
    name: 'LocalFileResults',
    component: LocalExternalFileResults,
    meta: { requiresAuth: false },
  },
  {
    path: '/log/:file_uuid',
    name: 'ExternalFileResult',
    component: ExternalFileResult,
    meta: { requiresAuth: false },
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  //sprawdzenie czy istenieje ciasteczko z wybranym językiem
  if (!VueCookieNext.isCookieAvailable('ServiceLanguage')) {
    store.commit('switchLanguage', 'eng');
    VueCookieNext.setCookie('ServiceLanguage', 'eng');
  }

  //sprawdzanie czy zalogowany
  if (to.meta.requiresAuth) {
    await store.dispatch('authUserSession');
  }

  if (to.name === "Home" && VueCookieNext.isCookieAvailable("Session")) {
    addBearerToken();
    API.get(process.env.VUE_APP_API_URL + '/api/user')
      .then(response => {
        router.push({ name: "UserFiles" });
      });
  }

  // odswiezanie strony na results view
  if (to.name === 'LocalFileResults' && store.getters.fileForView.headersArr.length === 0) {
    router.push({ name: 'Home' });
  }
});
export default router
