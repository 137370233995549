<template>
  <div class="About">
    <StartPageHeader />
    <BackButtonFixed
      :icon="'arrow-left'"
      @back="back"
      :backgroundColor="'#000000'"
    />
    <div class="About__content">
      <h2>
        {{ $store.getters.translations.whatAreLogs[$store.getters.language] }}?
      </h2>
      <p>
        {{
          $store.getters.translations.logsAreFilesToAnalyze[
            $store.getters.language
          ]
        }}
      </p>
      <div class="About__content__video-container desktop">
        <h2>
          {{
            $store.getters.translations.moreUsefulInfoBelow[
              $store.getters.language
            ]
          }}:
        </h2>
        <iframe
          width="660"
          height="415"
          src="https://www.youtube.com/embed/t0QazXcVVXs"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="About__content__video-container mobile">
        <h2>
          {{
            $store.getters.translations.moreUsefulInfoBelow[
              $store.getters.language
            ]
          }}:
        </h2>
        <iframe
          width="350"
          height="215"
          src="https://www.youtube.com/embed/t0QazXcVVXs"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <StartPageFooter />
  </div>
</template>

<script>
import StartPageHeader from "@/components/Platform/StartPageHeader.vue";
import StartPageFooter from "@/components/Platform/StartPageFooter.vue";
import BackButtonFixed from "@/components/Platform/BackButtonFixed.vue";

export default {
  name: "About",
  components: {
    StartPageHeader,
    StartPageFooter,
    BackButtonFixed,
  },
  methods: {
    back() {
      let isLogged = this.$store.getters.isLogged;
      if (isLogged) {
        this.$router.push({ name: "UserFiles" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style>
.About {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../assets/background.png");
  background-size: cover;
  min-height: 100vh;
}

.About__content {
  padding: 0 157px;
  text-align: left;
  font-size: 18px;
}

.About__content__log-description {
  margin-top: 5px;
}

.About__content h3 {
  margin-bottom: 0;
}

.About__content h2,
.About__content h3 {
  font-size: 700;
}

.About__content__video-container {
  text-align: center;
}

.About .About__content .mobile {
  display: none;
}

.About .About__content .desktop {
  display: block;
}

@media screen and (max-width: 769px) {
  .About {
    overflow: hidden;
  }

  .About .About__content {
   padding: 0 20px;
  }

  .About .About__content .mobile {
    display: block !important;
  }

  .About .About__content .desktop {
    display: none !important;
  }
}
</style>
