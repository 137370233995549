import errorMessages from '@/store/translations/errorMessages.js';
import { ElMessage, ElMessageBox } from 'element-plus';
import { API, removeBearerToken } from '@/config/API.js';
import VueCookieNext from '../cookies/config.js';
import router from '../router';
import store from '@/store';

const checkIfAuthenticated = (error) => {
    if(error.response.data.message === 'Unauthenticated.') {
        removeBearerToken();
        VueCookieNext.removeCookie('Session');
        store.commit('logOut');
        router.push({ name: 'Home' });
        ElMessage({
            showClose: true,
            message: store.getters.language === 'eng' ? 'Session has expired. Please login again.' : 'Sesja wygasła. Zaloguj się ponownie.',
            type: 'error',
            duration: 3000,
        });
        return Promise.reject(error.response);
    }
}

export const handleErrors = () => {
    API.interceptors.response.use(config => config, (error) => {
        checkIfAuthenticated(error);

        //sprawdzenie struktury obiektu w celu sprawdzenia czy pochodzi z JWT czy z requestu laravelowego
        if (!('exception' in error.response.data) && !('file' in error.response.data) && !('message' in error.response.data)) {
            for (let label in error.response.data) {
                error.response.data[label].forEach(message => {
                    setTimeout(() => {
                        ElMessage({
                            showClose: true,
                            //sprawdzenie wybranej wersji językowej
                            message: store.getters.language === 'eng' ? message : errorMessages[label][message],
                            type: 'error',
                            duration: 3000,
                        });
                    },500);
                });
            }
        } else {
            if (JSON.parse(error.response.data.message).code === 406) {
                for (const [label, value] of Object.entries(JSON.parse(error.response.data.message).data)) {
                    value.forEach(value => {
                        if (value === 'The link provided is invalid or the file has been deleted by the user.') {
                            ElMessageBox.alert(`${store.getters.language === 'eng' ? value : errorMessages[label][value]}`, store.getters.platformMessages.fileNotExist[store.getters.language], {
                                confirmButtonText: 'Ok',
                                customClass: 'Error-dialog-box',
                                callback: () => {
                                    router.push({ name: 'Home' })
                                },
                            });
                        }
                        setTimeout(() => {
                            ElMessage({
                                showClose: true,
                                message: store.getters.language === 'eng' ? value : errorMessages[label][value],
                                type: 'error',
                                duration: 4000,
                            });
                        },500);
                    })
                }
            }
        }
        return Promise.reject(error.response);
    });
}
