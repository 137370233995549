<template>
  <LineChart :chartData="chartData" :options="options" class="chart-container" />
</template>

<script>
import { LineChart } from 'vue-chart-3';

export default {
  name: "SecondaryChart",

  components: {
    LineChart,
  },

  props: {
    headersArr: {
      required: true,
      type: Object,
    },
    results: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      colors: ['#A52A2A', '#8B008B', '#FFA500', '#808000', '#FF0000', '#FFFF00', '#000000', '#00FFFF', '#00008B', '#FFD700', '#FF00FF', '#00FF00', '#BC8F8F', '#0000FF', '#7FFF00', '#7FFFD4'],
      options: {
        animations: {
          opacity: {
            duration: 1000,
            from: 0,
          }
        },
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        plugins: {
          title: {
            display: false,
            text: 'RPM',
            position: 'top',
          },
          legend: {
            display: true,
            position: 'top',
          }
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
        },
      },
    }
  },

  computed: {
    chartData() {
      let fileData = {
        labels: [],
        datasets: [],
      };
      let rpmColumnIndex = [];

      this.headersArr.forEach(row => {
        row.forEach((field, rpmIndex) => {
          if (field.includes('Engine Speed') || field.includes('Engine speed') || field.includes('engine speed') || field.includes('RPM') || field.includes('rpm') || field.includes('Obroty silnika') || field.includes('obroty silnika') || field.includes('OBROTY SILNIKA') || field.includes('Obroty Silnika')) {
            rpmColumnIndex = rpmIndex;
          }
        });
      });

      this.results.forEach(row => {
        fileData.labels.push(row[rpmColumnIndex] + ' RPM');
      });

      this.headersArr[4].forEach((groupName, indexOfGroup) => {
        if (groupName !== '' && groupName !== 'Engine Speed' && groupName !== 'Engine speed' && groupName !== 'engine speed' && groupName !== 'RPM' && groupName !== 'rpm' && groupName !== 'Rpm' && groupName !== 'Obroty silnika' && groupName !== 'obroty silnika' && groupName !== 'OBROTY SILNIKA' && groupName !== 'Obroty Silnika') {
          const decsriptionName = this.headersArr[5][indexOfGroup];
          const isBoostLabelExist = decsriptionName.includes('spec') || decsriptionName.includes('act') || decsriptionName.includes('Spec') || decsriptionName.includes('Act');
          const units = this.headersArr[6][indexOfGroup]
          let measurement = {
            label: isBoostLabelExist ? `${groupName} - ${decsriptionName}  (${units})` : `${groupName}  (${units})`,
            data: [],
            backgroundColor: this.colors[indexOfGroup],
            borderColor: this.colors[indexOfGroup],
            biggestValue: [],
            yAxisID: '',
            borderWidth: 2,
          }
          this.results.forEach(row => {
            if (row[indexOfGroup] !== '') {
              measurement.data.push(row[indexOfGroup]);
            }
          });
          measurement.biggestValue = Math.max.apply(null, measurement.data);
          if (measurement.biggestValue > 200) {
            measurement.yAxisID = 'y1';
          } else {
            measurement.yAxisID = 'y';
          }
          fileData.datasets.push(measurement);
        }
      });
      return fileData;
    },
  },
}
</script>
<style scoped>
canvas {
  min-height: 600px !important;
  max-height: 600px !important;
  display: block !important;
  touch-action: none !important;
  user-select: none !important;
}
</style>