<template>
  <div v-if="!isFileEdit" v-loading="isLoading" class="UserFilesListElement" :class="{
    addToFolderMode: isAddToFolderMode,
    additionalDisabledStyles: isFileAleradyExists,
  }">
    <div class="content">
      <div class="content__tech_info">
        <div class="content__tech_info__row">
          <span class="content__tech_info__row-label">{{
            $store.getters.translations.engine[this.$store.getters.language]
          }}:
          </span>
          <span class="content__tech_info__row-value">{{ file.engine }}</span>
        </div>

        <div class="content__tech_info__row">
          <span class="content__tech_info__row-label">ECU: </span>
          <span class="content__tech_info__row-value">{{ file.ecu }}</span>
        </div>
        <div class="content__tech_info__row">
          <span class="content__tech_info__row-label">{{
            $store.getters.translations.groups[this.$store.getters.language]
          }}:
          </span>
          <span class="content__tech_info__row-value">{{ file.groups }}</span>
        </div>
      </div>
      <div class="content__description">
        <div class="description-row folder-properties" v-if="nameOfTheFolderItContains">
          <font-awesome-icon class="folder-icon" :icon="['fas', 'folder-open']" />
          <span class="description-value folder-name">{{
            nameOfTheFolderItContains
          }}</span>
        </div>
        <div class="description-row">
          <span class="description-label">{{
            $store.getters.translations.title[this.$store.getters.language]
          }}:</span>
          <span class="description-value">{{ file.title }}</span>
        </div>
        <div class="description-row">
          <span class="description-label">{{
            $store.getters.translations.comment[this.$store.getters.language]
          }}:</span>
          <span class="description-value comment-value">{{
            file.comment
          }}</span>
        </div>
        <div class="description-row">
          <span class="description-label">{{
            $store.getters.translations.createdAt[
            this.$store.getters.language
            ]
          }}:</span>
          <span class="description-value">{{ file.created_at }}</span>
        </div>
      </div>
    </div>
    <div class="actions" v-if="!isAddToFolderMode">
      <el-tooltip class="item" effect="light" :content="$store.getters.translations.show[this.$store.getters.language]
        " placement="bottom">
        <el-button class="action-button">
          <img class="show-log-icon" src="@/assets/eye.svg" @click="loadCSV()" />
        </el-button>
      </el-tooltip>
      <el-popconfirm :confirm-button-text="$store.getters.translations.yes[this.$store.getters.language]
        " :cancel-button-text="$store.getters.translations.no[this.$store.getters.language]
    " icon="el-icon-info" icon-color="red" :title="`${$store.getters.translations.areYouSure[this.$store.getters.language]
    }?`" @confirm="deleteFile">
        <template #reference>
          <el-button class="action-button">
            <img class="remove-icon" src="@/assets/trash.svg" />
          </el-button>
        </template>
      </el-popconfirm>
      <el-tooltip class="item" effect="light" :content="$store.getters.translations.edit[this.$store.getters.language]
        " placement="bottom">
        <el-button class="action-button">
          <img class="edit-icon" src="@/assets/edit.svg" @click="editFile()" />
        </el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="light" :content="$store.getters.translations.share[this.$store.getters.language]
        " placement="bottom">
        <el-button class="action-button">
          <img class="share-icon" src="@/assets/share.svg" @click="openCopyLinkWindow()" />
        </el-button>
      </el-tooltip>
      <el-dialog v-model="isCopyLinkWindowVisible"
        :title="$store.getters.translations.share[this.$store.getters.language]" width="40%">
        <span class="copy-link-label">{{
          $store.getters.translations.shareLinkBelow[
          this.$store.getters.language
          ]
        }}</span>
        <div class="link-container">
          <span>https://vaglogs.com/log/{{ file.file_uuid }}</span>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="copyToClipboard()">{{
              $store.getters.translations.copyToClipboard[this.$store.getters.language] }}</el-button>
            <el-button type="danger" @click="isCopyLinkWindowVisible = false">{{
              $store.getters.translations.close[this.$store.getters.language] }}</el-button>
          </span>
        </template>
      </el-dialog>
      <span class="additionalRemoveIcon_container" v-if="isRemovingFromFolder" @click="deleteFileFromFolder">
        <font-awesome-icon class="additionalRemoveIcon" :icon="['fas', 'minus-circle']" />
      </span>
    </div>
    <div class="checkbox_container" v-else>
      <div class="checkbox_container__full_screen_layer">
        <el-checkbox-button class="checkbox_container__full_screen_layer__checkbox" size="large" v-model="addFileCheckbox"
          :disabled="isFileAleradyExists" @change="checkFile"><font-awesome-icon v-if="addFileCheckbox"
            class="additionalCheckedIcon" :icon="['fas', 'check']" />
          <span v-else>{{
            $store.getters.translations.add[$store.getters.language]
          }}</span></el-checkbox-button>
      </div>
    </div>
  </div>
  <FileEditor v-else @close-editor="cancelEditingFile" :file="file" />
</template>

<script>
import FileEditor from './FileEditor/FileEditor.vue';
import { ElMessage } from 'element-plus';

export default {
  name: 'UserListElement',

  components: {
    FileEditor,
  },

  props: {
    file: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
    },
    isAddToFolderMode: {
      required: false,
      type: Boolean,
    },
    selectedFolder: {
      required: false,
      type: Object,
      default: {},
    },
    isRemovingFromFolder: {
      required: false,
      type: Boolean,
      default: false,
    },
    foldersList: {
      requierd: false,
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      isFileEdit: false,
      isCopyLinkWindowVisible: false,
      addFileCheckbox: false,
      fileInfo: {
        title: this.file.title,
        comment: this.file.comment,
        groups: this.file.groups,
        ecu: this.file.ecu,
        engine: this.file.engine,
      },
      nameOfTheFolderItContains: null,
    };
  },

  created() {
    this.getNameOfTheFolderItContains();
    if (this.isFileAleradyExists) {
      this.addFileCheckbox = true;
    }
  },

  computed: {
    isLoading() {
      if (
        this.$store.getters.isListElementLoading.elementIndex === this.index
      ) {
        return this.$store.getters.isListElementLoading.bool;
      } else {
        return false;
      }
    },
    isFileAleradyExists() {
      return this.file.fk_folder_uuid === this.selectedFolder.folder;
    },
  },

  methods: {
    copyToClipboard() {
      const copyText = 'https://vaglogs.com/log/' + this.file.file_uuid;
      navigator.clipboard.writeText(copyText).then(() => {
        ElMessage({
          showClose: true,
          message: this.$store.getters.platformMessages.copiedToClipboard[this.$store.getters.language],
          type: 'success',
        });
      })
        .catch((error) => {
          ElMessage({
            showClose: true,
            message: error,
            type: 'error',
          });
        })
    },
    editFile() {
      this.isFileEdit = true;
    },

    cancelEditingFile() {
      this.isFileEdit = false;
    },

    async deleteFile() {
      const params = {
        file_uuid: this.file.file_uuid,
        index: this.index,
      };
      await this.$store.dispatch('deleteFile', params);
    },

    goToFileResultsView() {
      this.$router.push('/log/' + this.file.file_uuid);
    },

    openCopyLinkWindow() {
      this.isCopyLinkWindowVisible = true;
    },

    async csvJSON(csv) {
      const lines = csv.split('\n');
      let results = {
        headersArr: [],
        otherResults: [],
        fileInfo: this.fileInfo,
      };

      for (let i = 0; i <= 6; i++) {
        results.headersArr.push(lines[i].split(','));
      }

      lines.forEach((line, i) => {
        if (i > 6) {
          results.otherResults.push(line.split(','));
        }
      });
      results.otherResults.pop();
      await this.$store.commit('viewFileResults', results);
    },

    loadCSV() {
      this.csvJSON(this.file.body);
      this.goToFileResultsView();
    },
    checkFile() {
      this.$emit('check-file', this.file.file_uuid, this.addFileCheckbox);
    },
    deleteFileFromFolder() {
      this.$store.dispatch('deleteFileFromFolder', {
        file: this.file.file_uuid,
      });
    },
    getNameOfTheFolderItContains() {
      this.foldersList.forEach((folder) => {
        if (folder.folder_uuid === this.file.fk_folder_uuid) {
          this.nameOfTheFolderItContains = folder.name;
        }
      });
    },
  },
};
</script>
<style>
.UserFilesListElement {
  width: 230px;
  height: 270px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  transition: all 0.2s ease-in;
  position: relative;
  background: radial-gradient(92.98% 92.98% at 2.2% 1.86%,
      rgba(101, 101, 101, 0.5) 0%,
      rgba(34, 34, 34, 0) 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.UserFilesListElement:hover {
  box-shadow: -20px 20px 30px rgba(255, 255, 255, 0.096);
}

.UserFilesListElement .content {
  height: 90%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  line-height: 15px;
}

.UserFilesListElement .content .content__tech_info {
  height: 35%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
  border-bottom: 1px solid rgba(217, 217, 217, 0.25);
}

.UserFilesListElement .content .content__tech_info__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.UserFilesListElement .content .content__tech_info__row-label {
  font-weight: 300;
}

.UserFilesListElement .content .content__tech_info__row-value {
  font-weight: 500;
}

.UserFilesListElement .content .content__description {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 60%;
}

.UserFilesListElement .content .content__description .description-row {
  width: 100%;
  margin: 10px 0;
  text-align: left;
}

.UserFilesListElement .content .content__description .description-row.folder-properties {
  margin: 5px 0 0 0;
}

.UserFilesListElement .content .content__description .description-row .description-label {
  font-weight: 300;
}

.UserFilesListElement .content .content__description .description-value,
.UserFilesListElement .content .content__tech_info .content__tech_info__row-value {
  margin-left: 10px;
}

.UserFilesListElement .content .content__description .description-value.comment-value {
  overflow-wrap: break-word;
}

.UserFilesListElement .content .content__description .folder-properties .folder-icon {
  color: rgb(241, 213, 53);
}

.UserFilesListElement .actions {
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(172.19deg,
      #346671 0%,
      rgba(34, 34, 34, 0) 210.39%);
  border-radius: 0px 0px 12px 12px;
  padding: 0 15px;
}

.UserFilesListElement .actions .action-button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  transition: all .3s ease;
}

.UserFilesListElement .actions .action-button:hover {
  transform: scale(1.15);
}

.UserFilesListElement .el-popper {
  font-family: 'Montserrat', sans-serif;
}

.copy-link-label {
  font-size: 18px;
  font-weight: 700;
}

.link-container {
  margin-top: 10px;
  background: #333333;
  color: #fff;
  border-radius: 10px;
  padding: 20px 10px;
  font-size: 20px;
}

.addToFolderMode {
  margin: 0;
  cursor: pointer;
  background: radial-gradient(92.98% 92.98% at 2.2% 1.86%,
      rgba(101, 101, 101, 0.5) 0%,
      rgba(34, 34, 34, 0.5) 100%)
}

.addToFolderMode .checkbox_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
}

.additionalRemoveIcon_container {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-size: 24px;
}

.additionalRemoveIcon_container .additionalRemoveIcon {
  position: absolute;
  left: -2px;
  top: -1px;
  color: red;
  cursor: pointer;
  transition: 0.2s all ease-in;
}

.additionalRemoveIcon_container .additionalRemoveIcon:hover {
  transform: scale(1.1);
  color: rgb(255, 98, 98);
}

@media screen and (max-width: 769px) {
  .UserFilesListElement .actions .el-dialog {
    width: 95% !important;
  }

  .UserFilesList .UserFilesList_folders_container .UserFilesList_folders_container_add {
    flex-wrap: wrap;
    justify-content: center;
  }

  .UserFilesList .UserFilesList_folders_container .UserFilesList_folders_container_add .UserFilesList_folders_container_add_input_container {
    width: 80%;
    margin-left: 10px;
  }

  .UserFilesList .UserFilesList_folders_container .UserFilesList_folders_container_add .button-container {
    margin: auto;
    display: flex;
    width: 50%;
    justify-content: space-between;
  }
}
</style>