<template>
  <div class="Cookiebar">
    <div class="Cookiebar__content">
      <h3>{{ $store.getters.translations.aboutCookies[this.$store.getters.language] }}</h3>
      <p>
        {{ $store.getters.translations.serviceUsingCookies[this.$store.getters.language] }}
      </p>
    </div>
    <div class="Cookiebar__actions">
      <button class="Cookiebar__actions-close" @click="closeCookiebar">
        {{ $store.getters.translations.terms.iAccept[this.$store.getters.language] }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cookiebar',

  methods: {
    closeCookiebar() {
      this.$emit('closeCookiebar');
    }
  }
}

</script>
<style scoped>
.Cookiebar {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  background: rgb(38, 0, 70);
  color: #ffff;
  padding: 10px 25px;
  z-index: 1;
}

.Cookiebar .Cookiebar__content {
  width: 70%;
  text-align: left;
}

.Cookiebar .Cookiebar__content h3 {
  margin: 0;
}

.Cookiebar .Cookiebar__actions {
  display: flex;
  align-items: center;
}

.Cookiebar .Cookiebar__actions-close {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  padding: 15px 25px;
  font-family: "Montserrat";
}

@media screen and (max-width: 769px) {
  .Cookiebar {
    width: auto;
  }
}
</style>