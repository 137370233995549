<template>
  <button class="BackButtonFixed" :style="{ backgroundColor: backgroundColor }" @click="backEvent">
    <font-awesome-icon :icon="['fas', `${icon}`]" />
  </button>
</template>

<script>
export default {
  name: 'BackButtonFixed',

  props: {
    icon: {
      required: false,
      type: String,
    },
    backgroundColor: {
      required: false,
      type: String,
    }
  },

  methods: {
    backEvent() {
      this.$emit('back');
    }
  }
}
</script>
<style scoped>
.BackButtonFixed {
  color: #fff;
  background: #333333;
  padding: 10px;
  border: none;
  position: fixed;
  top: 25%;
  left: 0px;
  font-size: 24px;
  cursor: pointer;
  transition: all .3s ease;
  z-index: 2;
}

.BackButtonFixed:hover {
  background: rgb(0, 0, 0);
  color: #fff;
  padding-left: 20px;
}
</style>