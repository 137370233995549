<template>
  <div class="UploadFile">
    <h4>{{ $store.state.translations.uploadFiles[$store.getters.language] }}:</h4>
    <el-upload class="UploadFile__dragger" drag :on-change="loadCSV" action="" :auto-upload="false" accept=".csv">
      <i class="el-icon-upload"></i>
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">
        {{ $store.state.translations.dragger.dragFileHere[$store.getters.language] }} <em>{{
          $store.state.translations.dragger.clickViaSelectFile[$store.getters.language] }}!</em>
      </div>
      <template #tip>
        <div class="el-upload__tip">
        </div>
      </template>
    </el-upload>
  </div>
</template>

<script>
import { UploadFilled } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';

export default {
  name: "UploadFile",

  components: {
    UploadFilled,
  },

  data() {
    return {
      headersArr: [],
      results: [],
    };
  },

  methods: {
    goToFileResultsView() {
      this.$router.push({ name: "LocalFileResults" });
    },

    async csvJSON(csv) {
      let lines = csv.split("\n");
      let results = {
        headersArr: [],
        otherResults: [],
      }

      for (let i = 0; i <= 6; i++) {
        results.headersArr.push(lines[i].split(','));
      }

      lines.forEach((line, i) => {
        if (i > 6) {
          results.otherResults.push(line.split(','));
        }
      });
      results.otherResults.pop();
      await this.$store.commit('viewFileResults', results);
    },

    loadCSV(e) {
      if (e.name.substr(-4) == '.csv' || e.name.substr(-4) == '.CSV' || e.name.substr(-4) === '.Csv') {
        if (window.FileReader) {
          let reader = new FileReader();
          reader.readAsText(e.raw);

          reader.onload = event => {
            let csv = event.target.result;
            this.csvJSON(csv);
            this.goToFileResultsView();
          };

          reader.onerror = function (evt) {
            if (evt.target.error.name == "NotReadableError") {
              alert("Canno't read file !");
            }
          };
        } else {
          alert('FileReader are not supported in this browser.');
        }
      } else {
        ElMessage({
          showClose: true,
          message: this.$store.getters.platformMessages.invalidFileFormat[this.$store.getters.language],
          type: 'error',
          duration: 3000,
        });
      }
    },
  },
}
</script>
<style>
.UploadFile {
  width: 100%;
  margin: 10px auto;
  color: #fff;
}

.UploadFile .el-upload .el-upload-dragger {
  width: auto;
  padding: 40px;
}

.UploadFile h4 {
  font-size: 26px;
}

@media screen and (max-width: 769px) {
  .UploadFile {
    display: none;
  }
}
</style>