import axios from 'axios';
import { handleErrors } from '@/config/interceptor.js';
import VueCookieNext from '../cookies/config.js';

const API_HEADERS = {
  'Access-Control-Allow-Origin': true,
  'Access-Control-Allow-Credentials': true,
  'Access-Control-Max-Age': 1000,
  'X-Requested-With': 'XMLHttpRequest',
  'Content-type': 'application/json',
};
const API_CONFIG = {};

API_CONFIG.headers = API_HEADERS;
API_CONFIG.timeout = 25000;
export let API = axios.create(API_CONFIG);

export const addBearerToken = () => {
  API = axios.create({
    timeout: API_CONFIG.timeout,
    headers: {
      ...API_HEADERS,
      Authorization: `Bearer ${VueCookieNext.getCookie('Session')}`,
    },
  });
  handleErrors();
};

export const removeBearerToken = () => {
  API = axios.create(API_CONFIG);
  handleErrors();
};

handleErrors();
