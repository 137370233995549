export default {
    email: {
        'The email has already been taken.': 'Podany adres email jest już zajęty',
        'Email no exists.': 'Nieprawidłowy adres Email',
        'Email address is invalid! Check if it contains the "@".': 'Nieprawidłowy adres Email',
        'Email address is required.': 'Adres email jest wymagany',
        'Email is too long.': 'Wprowadzony adres email jest za długi'
    },
    name: {
        'The name must be between 2 and 100 characters.': 'Nazwa użytkownika musi zawierać przynajmniej dwa znaki.',
        'The name field is required.': 'Nazwa użytkownika musi zawierać przynajmniej dwa znaki.',
        'Folder name is too long.': 'Wprowadzona nazwa folderu jest za długa',
    },
    password: {
        'The password confirmation does not match.': 'Podane hasła są różne.',
        'The password must be at least 5 characters.': 'Hasło musi składać się z co najmniej 5 znaków.',
        'The password field is required.': 'Hasło musi składać się z co najmniej 5 znaków.',
        'The password is too long.': 'Wprowadzone hasło jest za długie',
    },
    confirmation: {
        'Your account has not been activated by link sent on email.': 'Twoje konto nie zostało aktywowane przez link wysłany na maila',
    },
    login: {
        'Invalid email or password': 'Nieprawidłowy login lub hasło'
    },
    file_to_view: {
        'The link provided is invalid or the file has been deleted by the user.': 'Podany link jest nieprawidłowy lub plik został usunięty przez użytkownika.'
    },
    file: {
        'File not exists': 'Plik nie istnieje',
    },
    folder: {
        'Folder not exists': 'Folder nie istnieje',
    },
    user: {
        'User not exists': 'Użytkownik nie istnieje',
    },
    token: {
        'Password reset link is expired': 'Link do resetowania hasła wygasł',
    },
    title: {
        'Title is too long.': 'Wprowadzony tytuł jest za długi',
    },
    comment: {
        'Comment is too long.': 'Wprowadzony komentarz jest za długi',
    }
}