<template>
  <div class="UserFilesListFolder" :class="{ expand: isExpanded }" v-loading="isLoading">
    <div class="UserFilesListFolder_header" v-if="!isFolderEdit">
      <font-awesome-icon class="folder-icon" :icon="['fas', 'folder-open']" />
      <span class="UserFilesListFolder_header_text-main">{{
        folder.name
      }}</span>
      <button class="UserFilesListFolder_header_add_file_button" @click="toggleAddToFolderMode">
        <span class="UserFilesListFolder_header_add_file_button_icon">+</span>
        <span>{{
          $store.getters.translations.addFiles[this.$store.getters.language]
        }}</span>
      </button>
      <span class="UserFilesListFolder_header_text-secondary">{{
        folder.created_at
      }}</span>
      <el-popconfirm :confirm-button-text="$store.getters.translations.yes[this.$store.getters.language]
          " :cancel-button-text="$store.getters.translations.no[this.$store.getters.language]
      " icon="el-icon-info" icon-color="red" :title="`${$store.getters.translations.areYouSure[this.$store.getters.language]
    }?`" @confirm="deleteFolder">
        <template #reference>
          <el-button size="large" class="action-button">
            <img class="remove-icon" src="@/assets/trash.svg" />
          </el-button>
        </template>
      </el-popconfirm>
      <el-tooltip class="item" effect="light" :content="$store.getters.translations.edit[this.$store.getters.language]
        " placement="bottom">
        <el-button size="large" class="action-button">
          <img class="edit-icon" src="@/assets/edit.svg" @click="toggleFolderEditor" />
        </el-button>
      </el-tooltip>
      <span class="action-button" @click="toggleExpand"><font-awesome-icon class="expand-icon"
          :icon="['fas', 'chevron-down']" /></span>
    </div>
    <FolderEditor v-else @toggle-folder-editor="toggleFolderEditor" :folder="folder" />
    <div class="UserFilesListFolder_contents">
      <div class="UserFilesList_element_container" element-loading-background="rgba(0, 0, 0, 0)">
        <span class="empty-folder-label"
          v-if="!filesInFolder.length">{{ $store.getters.translations.emptyFolder[this.$store.getters.language] }}</span>
        <transition-group name="slide-fade" mode="out-in">
          <UserListElement v-for="(file, index) in filesInFolder" :key="file.file_uuid" :file="file" :index="index"
            :is-removing-from-folder="true" />
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import FolderEditor from './FolderEditor/FolderEditor.vue';
import UserListElement from '@/components/Common/UserFilesList/UserListElement/UserListElement.vue';

export default {
  name: 'UserFolders',

  components: {
    FolderEditor,
    UserListElement,
  },

  props: {
    folder: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    filesList: {
      type: Array,
      required: false,
      default: [],
    }
  },

  watch: {
    filesList() {
      this.searchFilesByFolder();
    }
  },

  data() {
    return {
      isExpanded: false,
      isFolderEdit: false,
      filesInFolder: [],
    }
  },

  created() {
    this.searchFilesByFolder();
  },

  computed: {
    isLoading() {
      if (this.$store.getters.isListElementLoading.elementIndex === this.index) {
        return this.$store.getters.isListElementLoading.bool;
      } else {
        return false;
      }
    },
  },

  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    deleteFolder() {
      this.$store.dispatch('deleteFolder', { folder: this.folder.folder_uuid, index: this.index })
    },
    toggleFolderEditor() {
      this.isFolderEdit = !this.isFolderEdit;
    },
    searchFilesByFolder() {
      this.filesInFolder = this.filesList.filter(file => {
        if (file.fk_folder_uuid === this.folder.folder_uuid) {
          return file;
        }
      });
    },
    toggleAddToFolderMode() {
      this.$emit('toggle-add-to-folder-mode', 'files', { folder: this.folder.folder_uuid, name: this.folder.name });
    }
  },
}
</script>
<style scoped>
.UserFilesListFolder {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  transition: all .2s ease-in;
  border-radius: 5px;
  background: linear-gradient(172.19deg, #346671 0%, rgba(34, 34, 34, 0) 210.39%);
  margin: 15px 0;
}

.UserFilesListFolder_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding: 10px;
}

.UserFilesListFolder_header_add_file_button {
  margin-left: auto;
  border: none;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.UserFilesListFolder_header_add_file_button_icon {
  margin-right: 7px;
  font-size: 20px;
  transition: all .2s ease-in;
}


.UserFilesListFolder_header_add_file_button:hover .UserFilesListFolder_header_add_file_button_icon {
  transform: rotate(90deg);
}

.UserFilesListFolder_header_text-secondary {
  font-size: 14px;
  margin: 0 10px 0 30px;
}

.UserFilesListFolder_header .folder-icon {
  margin-right: 50px;
  color: rgb(241, 213, 53);
}

.UserFilesListFolder_header .expand-icon {
  margin: 0 10px;
  transition: all .2s ease-in;
}

.UserFilesListFolder_header .action-button {
  background: transparent;
  border: none;
  font-size: 18px;
  padding: 0 10px;
  margin: 0 !important;
  cursor: pointer;
  transition: all 0.3s ease;
}

.UserFilesListFolder_header .action-button:hover {
  background-color: transparent;
  transform: scale(1.15);
}

.UserFilesListFolder_contents {
  background: #1d1d1d;
  transition: all .3s ease-in;
  max-height: 0px;
  opacity: 0;
  overflow-y: hidden;
  border: none;
  padding: 0 25px;
}

.UserFilesListFolder_contents .empty-folder-label {
  opacity: 0.7;
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
}

.UserFilesListFolder_contents .UserFilesList_element_container {
  min-height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  row-gap: 24px;
  column-gap: 32px;
  padding: 23px 0;
}

.expand .expand-icon {
  transform: rotate(180deg);
}

.expand .UserFilesListFolder_contents {
  max-height: 3000px;
  opacity: 1;
  overflow-y: visible;
}

@media screen and (max-width: 769px) {
  .UserFilesListFolder_header .folder-icon {
    margin-right: 5px;
  }

  .UserFilesListFolder_header .UserFilesListFolder_header_add_file_button .UserFilesListFolder_header_add_file_button_icon {
    margin-right: 5px;
  }

  .UserFilesListFolder_header .UserFilesListFolder_header_text-secondary {
    display: none;
  }
}
</style>