<template>
  <div class="StartPageHeader">
    <header class="StartPageHeader__container">
      <div class="StartPageHeader__container__logo-container">
        <a href="https://vaglogs.com" class="logo-text" v-if="!isLogged">vaglogs<span class="logo-text__dot">.</span></a>
        <a href="https://vaglogs.com/files" class="logo-text" v-else>vaglogs<span class="logo-text__dot">.</span></a>
        <span class="logo-version">v 1.5</span>
      </div>
      <font-awesome-icon class="hamburger" :icon="['fas', 'bars']" @click="toggleCollapseMenu" />
      <div class="StartPageHeader__menu-items" :class="{ visible: isMenuCollapsed }">
        <div class="StartPageHeader__menu-items__item" v-if="!isLogged">
          <span class="about-label"><a href="http://page.vaglogs.com/">{{
            $store.getters.translations.aboutButton[$store.getters.language]
          }} <span class="logo-text">vaglogs<span class="logo-text__dot">.</span></span></a></span>
        </div>
        <div class="StartPageHeader__menu-items__item" v-if="!isLogged">
          <span class="about-label" @click="goToAboutView">{{
            $store.getters.translations.whatAreLogs[$store.getters.language]
          }}</span>
        </div>
        <transition name="fade">
          <div v-if="this.$store.getters.isLogged" class="menu-user-profile">
            <div class="user-icon-container">
              <img class="user-icon" src="@/assets/user.png" />
            </div>
            <div class="user-data-container">
              <div class="user-data-container__row">
                <span class="user-data-container_label">
                  {{
                    $store.getters.translations.name[$store.getters.language]
                  }}:
                </span>
                <span class="user-data-container_value">{{
                  userData.name
                }}</span>
              </div>
              <div class="user-data-container__row">
                <span class="user-data-container_label">E-mail: </span>
                <span class="user-data-container_value">
                  {{ userData.email }}
                </span>
              </div>
            </div>
          </div>
        </transition>
        <div class="button-container" v-if="this.$store.getters.isLogged">
          <button class="logout" @click="logOut()">
            {{ $store.getters.translations.logout[$store.getters.language] }}
          </button>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { API, removeBearerToken } from '@/config/API.js'
import { ElMessage } from 'element-plus';

export default {
  name: "StartPageHeader",

  data() {
    return {
      isMenuCollapsed: false,
    }
  },

  computed: {
    userData() {
      return this.$store.getters.user;
    },
    isLogged() {
      return this.$store.getters.isLogged;
    }
  },

  methods: {
    goToStartPage() {
      this.$router.push({ name: "Home" });
    },
    goToUserView() {
      this.$router.push({ name: "UserFiles" });
    },
    goToAboutView() {
      this.$router.push({ name: "About" });
    },
    toggleCollapseMenu() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    },
    async logOut() {
      const config = {
        headers: { Authorization: `Bearer ${this.$cookie.getCookie('Session')}` }
      };
      const bodyParameters = {
        key: "value"
      };
      try {
        await API.post(process.env.VUE_APP_API_URL + '/api/logout', bodyParameters, config);
      } catch (error) { }
      removeBearerToken();
      this.$store.commit('logOut');
      this.goToStartPage();
      ElMessage({
        showClose: true,
        message: this.$store.getters.platformMessages.logoutSuccess[this.$store.getters.language],
        type: 'success',
      });
      this.$cookie.removeCookie('Session');
    }
  }
};
</script>
<style>
.StartPageHeader {
  height: 130px;
  box-sizing: border-box;
  padding: 0 157px;
}

.StartPageHeader hr {
  margin-top: 0;
}

.StartPageHeader .hamburger {
  display: none;
}

.StartPageHeader__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  border-bottom: 1px solid rgba(217, 217, 217, 0.25);
}

.StartPageHeader .StartPageHeader__container__logo-container {
  width: 165px;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 32px;
  line-height: 34px;
  position: relative;
}

.StartPageHeader .logo-text {
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-family: 'Archivo', sans-serif;
}

.StartPageHeader .logo-text__dot {
  color: #48d7d7;
}

.StartPageHeader .StartPageHeader__container__logo-container .logo-version {
  font-size: 11px;
  transform: rotate(35deg);
  position: absolute;
  right: 20%;
  bottom: 45%;
  color: #48d752;
  font-weight: bold;
}

.StartPageHeader__menu-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 70%;
}

.StartPageHeader__menu-items .StartPageHeader__menu-items__item {
  margin-left: 5px;
  font-size: 22px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  transition: all .1s ease-in;
}

.StartPageHeader__menu-items .StartPageHeader__menu-items__item:hover {
  transform: scale(1.05);
}

.StartPageHeader__menu-items .StartPageHeader__menu-items__item .about-label {
  cursor: pointer;
}

.StartPageHeader__menu-items .StartPageHeader__menu-items__item .about-label a {
  color: #fff;
  text-decoration: none;
}

.StartPageHeader__menu-items .StartPageHeader__menu-items__item .about-item-icon {
  margin: 0 7px;
  font-size: 28px;
}

.StartPageHeader__menu-items .menu-user-profile {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: #fff;
}

.StartPageHeader__menu-items .button-container {
  display: flex;
  align-items: center;
  margin-left: 39px;
}

.StartPageHeader__menu-items .menu-user-profile .user-icon-container {
  font-size: 58px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.StartPageHeader__menu-items .menu-user-profile .user-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}

.StartPageHeader__menu-items .menu-user-profile .user-data-container .user-data-container__row {
  text-align: left;
}

.StartPageHeader__menu-items .button-container .logout {
  color: #fff;
  background: rgba(0, 168, 168, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  width: 112px;
  height: 40px;
  cursor: pointer;
  font-weight: 600;
  line-height: 19.5px;
  transition: all 0.3s ease;
  font-family: "Montserrat";
}

.StartPageHeader__menu-items .button-container .logout:hover {
  background: rgba(53, 219, 219, 1);
}

.StartPageHeader__menu-items .green {
  color: rgb(0, 255, 76);
}

@media screen and (max-width: 970px) {
  .StartPageHeader {
    padding: 20px 50px 0 50px;
  }
}

@media screen and (max-width: 769px) {
  .StartPageHeader {
    padding: 20px 20px 0 20px;
    height: auto;
  }

  .StartPageHeader__container {
    flex-wrap: wrap;
    align-items: center;
    min-height: 75px;
  }

  .StartPageHeader .StartPageHeader__container .StartPageHeader__container__logo-container {
    height: auto;
  }

  .StartPageHeader__menu-items {
    width: 100%;
    padding: 0;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
    height: 0px;
    opacity: 0;
    flex-wrap: wrap;
  }

  .StartPageHeader__menu-items .StartPageHeader__menu-items__item {
    margin-top: 10px;
    margin-left: auto;
  }

  .StartPageHeader .hamburger {
    font-size: 26px;
    cursor: pointer;
    display: block;
  }

  .StartPageHeader .visible {
    height: 100px;
    margin: 35px 0 10px 0;
    opacity: 1;
  }

  .StartPageHeader .StartPageHeader__menu-items .menu-user-profile,
  .StartPageHeader .StartPageHeader__menu-items .button-container {
    display: none;
  }

  .StartPageHeader .visible .menu-user-profile,
  .StartPageHeader .visible .button-container {
    display: flex;
    opacity: 1;
  }

  .StartPageHeader__menu-items .menu-user-profile .user-data-container tr {
    background: transparent;
  }

  .StartPageHeader__menu-items .menu-user-profile .user-data-container td {
    border: none;
  }

  .StartPageHeader__menu-items .menu-user-profile .user-data-container .user-data-container_value {
    color: #fff;
    font-weight: 700;
  }

  .StartPageHeader__menu-items .menu-user-profile .user-data-container .user-data-container_label {
    display: none;
  }
}

@media screen and (max-width: 379px) {
  .StartPageHeader__menu-items .button-container {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
}
</style>


