<template>
  <div class="ExternalFileResultsView">
    <StartPageHeader />
    <div class="ExternalFileResultsViewContainer" v-loading="!dataReady">
      <div class="ExternalFileResultsView__settings">
        <el-switch
          v-model="isChartViewEnabled"
          class="mb-2"
          active-color="#13ce66"
          inactive-color="#0276FD"
          :active-text="
            $store.getters.translations.showChart[$store.getters.language]
          "
          :inactive-text="
            $store.getters.translations.showTable[$store.getters.language]
          "
        />
      </div>
      <transition name="fade">
        <SimpleFileResults
          v-if="!isChartViewEnabled && dataReady"
          :headers-arr="headers"
          :results="otherResults"
          :file-info="fileInfo"
        />
        <Chart
          v-else-if="isChartViewEnabled && dataReady"
          :headers-arr="headers"
          :results="otherResults"
          :file-info="fileInfo"
        />
      </transition>
    </div>
    <StartPageFooter />
  </div>
</template>

<script>
import StartPageHeader from "@/components/Platform/StartPageHeader.vue";
import SimpleFileResults from "@/components/Common/SimpleFileResults.vue";
import Chart from "@/components/Common/Chart.vue";
import StartPageFooter from "@/components/Platform/StartPageFooter.vue";
import { API } from "@/config/API.js";

export default {
  name: "ExternalFileResultsView",
  components: {
    StartPageHeader,
    SimpleFileResults,
    StartPageFooter,
    Chart,
  },

  data() {
    return {
      isChartViewEnabled: false,
      fileData: [],
      headers: [],
      otherResults: [],
      fileInfo: {},
      dataReady: false,
    };
  },

  async created() {
    await API.get(process.env.VUE_APP_API_URL+"/api/files/getFile", {
      params: { file_to_view: this.$route.params.file_uuid },
    }).then((response) => {
      this.fileData = response.data.data[0];
      this.csvJSON(this.fileData.body);
    });
    this.dataReady = true;
  },

  methods: {
    async csvJSON(csv) {
      let lines = csv.split("\n");
      let results = {
        headersArr: [],
        otherResults: [],
        fileInfo: {
          title: this.fileData.title,
          comment: this.fileData.comment,
          groups: this.fileData.groups,
        },
      };

      for (let i = 0; i <= 6; i++) {
        results.headersArr.push(lines[i].split(","));
      }

      lines.forEach((line, i) => {
        if (i > 6) {
          results.otherResults.push(line.split(","));
        }
      });
      results.otherResults.pop();
      this.headers = results.headersArr;
      this.otherResults = results.otherResults;
      this.fileInfo = results.fileInfo;
    },
  },
};
</script>

<style>
.ExternalFileResultsView {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-image: url("../assets/background.png");
  background-size: cover;
  background-attachment: fixed;
  font-family: "Poppins", sans-serif;
}

.ExternalFileResultsView .ExternalFileResultsViewContainer {
  background: #fff;
}

.ExternalFileResultsView__settings {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 3px 15px;
  border-bottom: 1px solid rgb(173, 173, 173);
}

@media screen and (max-width: 450px) {
  .ExternalFileResultsView__settings {
    padding-bottom: 10px !important;
  }
}
</style>