<template>
  <div class="FileInfoSection__log-info">
    <font-awesome-icon class="log-info-icon" :icon="['fas', 'info-circle']" />
    <div class="column">
      <div class="row">
        <div class="info-name">
          <h3>{{ $store.getters.translations.date[$store.getters.language] }}:</h3>
        </div>
        <div class="info-value">
          <span v-for="record in parsedDate" :key="record">{{ ` ${record}` }}</span>
        </div>
      </div>
      <div class="row">
        <div class="info-name">
          <h3>{{ $store.getters.translations.engine[$store.getters.language] }}:</h3>
        </div>
        <div class="info-value">
          <span>{{ fileInfo.engine }}</span>
        </div>
      </div>
      <div class="row">
        <div class="info-name">
          <h3>ECU:</h3>
        </div>
        <div class="info-value">
          <span>{{ fileInfo.ecu }}</span>
        </div>
      </div>
    </div>
    <div class="column" v-if="fileInfo.title && fileInfo.comment && fileInfo.groups">
      <div class="row">
        <div class="info-name">
          <h3>{{ $store.getters.translations.title[$store.getters.language] }}:</h3>
        </div>
        <div class="info-value">
          <span>{{ fileInfo.title }}</span>
        </div>
      </div>
      <div class="row">
        <div class="info-name">
          <h3>{{ $store.getters.translations.comment[$store.getters.language] }}:</h3>
        </div>
        <div class="info-value">
          <span>{{ fileInfo.comment }}</span>
        </div>
      </div>
      <div class="row">
        <div class="info-name">
          <h3>{{ $store.getters.translations.groups[$store.getters.language] }}:</h3>
        </div>
        <div class="info-value">
          <span>{{ fileInfo.groups }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileInfoSection',

  props: {
    fileInfo: {
      required: true,
      type: Object,
    }
  },

  data() {
    return {
      dateValuesToParse: {
        'January': 'Styczeń',
        'February': 'Luty',
        'March': 'Marzec',
        'April': 'Kwiecień',
        'May': 'Maj',
        'June': 'Czerwiec',
        'July': 'Lipiec',
        'August': 'Sierpień',
        'September': 'Wrzesień',
        'October': 'Październik',
        'November': 'Listopad',
        'December': 'Grudzień',
        'Monday': 'Poniedziałek',
        'Tuesday': 'Wtorek',
        'Wednesday': 'Środa',
        'Thursday': 'Czwartek',
        'Friday': 'Piątek',
        'Saturday': 'Sobota',
        'Sunday': 'Niedziela'
      },
    }
  },

  computed: {
    parsedDate() {
      let dateRow = [...this.fileInfo.dateRow];
      const newDateRow = dateRow.filter((dateElement, index) => {
        if (index <= 4) {
          return dateElement;
        }
      });
      newDateRow[newDateRow.length - 1] = newDateRow[newDateRow.length - 1].substr(0, 5);
      if (this.$store.getters.language === 'pl') {
        newDateRow.forEach((el, dateRowIndex) => {
          for (let valueToParse in this.dateValuesToParse) {
            if (el.includes(valueToParse)) {
              newDateRow[dateRowIndex] = this.dateValuesToParse[valueToParse];
            }
          }
        });
      }
      return newDateRow;
    }
  },
}
</script>
<style scoped>
.FileInfoSection__log-info {
  max-width: 100%;
  color: #000;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  padding: 10px 30px;
  font-size: 14px;
  overflow: hidden;
}

.FileInfoSection__log-info .log-info-icon {
  font-size: 120px;
  position: absolute;
  right: -30px;
  top: -25px;
  z-index: -1;
  opacity: .3;
  color: rgb(66, 189, 189);
}

.FileInfoSection__log-info .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FileInfoSection__log-info .row {
  display: flex;
  flex-direction: row;
  text-align: left;
}

.FileInfoSection__log-info .row .info-name {
  width: 150px;
}

.FileInfoSection__log-info .row .info-value {
  width: 70%;
  font-size: 1.17em;
}

.FileInfoSection__log-info h3 {
  margin: 0;
}

@media screen and (max-width: 769px) {
  .FileInfoSection__log-info {
    margin-top: 10px;
    padding: 0 10px;
    flex-wrap: wrap;
  }

  .FileInfoSection__log-info .column {
    width: 100%;
  }

  .FileInfoSection__log-info .column .row .info-value {
    text-align: right;
  }
}
</style>