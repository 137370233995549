<template>
  <div class="Home">
    <StartPageHeader />
    <StartPageLayout />
    <StartPageFooter />
    <transition name="fade">
      <Cookiebar v-if="isCookiebarVisible" @close-cookiebar="closeCookiebar" />
    </transition>
    <LanguageSwitcher />
  </div>
</template>

<script>
import StartPageHeader from "@/components/Platform/StartPageHeader.vue";
import StartPageLayout from "@/components/Platform/StartPageLayout.vue";
import StartPageFooter from "@/components/Platform/StartPageFooter.vue";
import LanguageSwitcher from "@/components/Platform/LanguageSwitcher.vue";
import { ElMessage } from "element-plus";
import Cookiebar from "@/components/Platform/Cookiebar.vue";

export default {
  name: "Home",

  components: {
    StartPageHeader,
    StartPageLayout,
    StartPageFooter,
    LanguageSwitcher,
    Cookiebar,
  },

  data() {
    return {
      isCookiebarVisible: false,
    };
  },

  created() {
    this.emailConfirmationMessage();
    this.checkCookiebarCookie();
  },

  methods: {
    emailConfirmationMessage() {
      if (this.$route.params.confirmation_status === "email_confirmed") {
        ElMessage(
          {
            showClose: true,
            message:
              this.$store.getters.platformMessages.accountHasBeenActivated[
              this.$store.getters.language
              ],
            type: "success",
            duration: 3000,
          }
        );
      } else if (
        this.$route.params.confirmation_status === "confirmation_error"
      ) {
        ElMessage(
          {
            showClose: true,
            message:
              this.$store.getters.platformMessages.accountActivationError[
              this.$store.getters.language
              ],
            type: "error",
            duration: 5000,
          }
        );
      }
    },
    checkCookiebarCookie() {
      if (!this.$cookie.isCookieAvailable("Cookiebar")) {
        setTimeout(() => {
          this.isCookiebarVisible = true;
        }, 750);
      }
    },
    closeCookiebar() {
      this.isCookiebarVisible = false;
      this.$cookie.setCookie("Cookiebar", false);
    },
  },
};
</script>

<style>
.Home {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../assets/background.png");
  background-size: cover;
}
</style>
