<template>
  <div class="RegisterModal__secondary-bg">
    <div class="RegisterModal">
      <font-awesome-icon class="RegisterModal__close-icon" :icon="['fas', 'times']" @click="openOrCloseModal(false)" />
      <el-form :model="registerForm" status-icon :rules="rules" ref="registerForm" label-width="200px"
        class="RegisterModal__register-form" label-position="top">
        <el-form-item :label="$store.getters.translations.name[this.$store.getters.language]
          " prop="name" required>
          <el-input v-model="registerForm.name"></el-input>
        </el-form-item>

        <el-form-item label="Email" prop="email" required>
          <el-input v-model="registerForm.email"></el-input>
        </el-form-item>

        <el-form-item :label="$store.getters.translations.password[this.$store.getters.language]
          " prop="password" required>
          <el-input type="password" v-model="registerForm.password"></el-input>
        </el-form-item>

        <el-form-item :label="$store.getters.translations.repeatPassword[
          this.$store.getters.language
          ]
          " prop="password_confirmation" required>
          <el-input type="password" v-model="registerForm.password_confirmation"></el-input>
        </el-form-item>
        <div class="RegisterModal__register-form__terms-checkbox__container">
          <el-form-item prop="is_terms_accepted" required>
            <el-checkbox v-model="registerForm.is_terms_accepted" size="medium"
              name="is_terms_accepted"></el-checkbox><span class="RegisterModal__register-form__terms-label">{{
                $store.getters.translations.terms.iAccept[
                  this.$store.getters.language
                ]
              }}
              <span class="RegisterModal__register-form__terms-label-link" @click="showTerms">{{
                $store.getters.translations.terms.theTerms[
                this.$store.getters.language
                ]
              }}</span>
              {{
                $store.getters.translations.terms.ofWebiste[
                  this.$store.getters.language
                ]
              }}</span>
          </el-form-item>
        </div>

        <vue-recaptcha v-if="showRecaptcha" sitekey="6LcabtgcAAAAAPbtN31PN6KSxL99pQBgx3shezgD" size="normal" theme="light"
          :tabindex="0" @verify="recaptchaVerified" @expire="recaptchaExpired" @fail="recaptchaFailed" ref="vueRecaptcha">
        </vue-recaptcha>

        <el-form-item>
          <el-button size="large" class="register-button" type="primary" @click="registerButtonSubmit()"
            :disabled="!isVerifiedCaptcha || !registerForm.is_terms_accepted" :loading="isRegistering">{{
              $store.getters.translations.register[this.$store.getters.language]
            }}</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog v-model="isTermsVisible" :title="$store.getters.translations.termsModal.termsOfService[
      this.$store.getters.language
      ]
      " top="30vh" width="60%">
      <template #default>
        <div class="terms-list">
          <ol>
            <li v-for="term in $store.getters.translations.termsModal.termsList" :key="term['eng']">
              {{ term[this.$store.getters.language] }}
            </li>
          </ol>
        </div>
      </template>
      <template #footer>
        <div class="dialog-footer">
          <el-button size="large" @click="isTermsVisible = false">{{
            $store.getters.translations.close[this.$store.getters.language]
          }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';
import { ElMessage } from 'element-plus';

export default {
  name: "RegisterModal",

  components: {
    vueRecaptcha
  },

  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$store.getters.translations.emailRequired[this.$store.getters.language]));
      } else {
        if (!this.validateEmail()) {
          callback(new Error(this.$store.getters.platformMessages.invalidEmail[this.$store.getters.language]));
        } else {
          callback();
        }
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$store.getters.translations.passwordRequired[this.$store.getters.language]));
      } else {
        if (value.length < 5) {
          callback(new Error(this.$store.getters.translations.passwordMustBe[this.$store.getters.language]));
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$store.getters.translations.repeatPassword[this.$store.getters.language]));
      } else if (value !== this.registerForm.password) {
        callback(new Error(this.$store.getters.translations.passwordNotMatch[this.$store.getters.language]));
      } else {
        callback();
      }
    };
    return {
      showRecaptcha: true,
      isVerifiedCaptcha: false,
      isRegistering: false,
      isTermsVisible: false,
      registerForm: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        is_terms_accepted: false,
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$store.getters.translations.nameRequired[this.$store.getters.language],
            trigger: 'blur',
          },
          {
            min: 2,
            max: 100,
            message: this.$store.getters.translations.nameMustContain[this.$store.getters.language],
            trigger: 'blur',
          },
        ],
        email: [
          {
            validator: validateEmail,
            trigger: 'blur',
          }
        ],
        password: [
          {
            validator: validatePass,
            trigger: 'blur',
          },
        ],
        password_confirmation: [
          {
            validator: validatePass2,
            trigger: 'blur',
          },
        ],
        is_terms_accepted: [
          {
            type: 'boolean',
            validator: this.validateCheckbox,
            trigger: 'change',
          }
        ],
      },
    }
  },

  methods: {
    openOrCloseModal() {
      this.$emit('openCloseModal', false);
    },
    recaptchaVerified(response) {
      if (response) {
        this.isVerifiedCaptcha = true;
      }
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      ElMessage({
        showClose: true,
        message: 'Błąd autoryzacji',
        type: 'error',
      });
    },
    async registerButtonSubmit() {
      this.registerForm.email = this.registerForm.email.trim();
      //sprawdza prawidłowośc adresu email, jestli jest ok, wykonuje akcje rejestracji
      if (this.validateEmail()) {
        this.isRegistering = true;
        const result = await this.$store.dispatch('registerToPlatform', this.registerForm);
        if (result.status === 201 || result.status === 200) {
          ElMessage({
            showClose: true,
            message: this.$store.getters.platformMessages.activationLinkSent[this.$store.getters.language],
            duration: 6000,
            type: 'success',
          });
          this.openOrCloseModal(false);
        }
        this.isRegistering = false;
      } else {
        ElMessage({
          showClose: true,
          message: this.$store.getters.platformMessages.invalidEmail[this.$store.getters.language],
          type: 'error',
        });
      }
    },

    validateEmail() {
      const rule = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return rule.test(this.registerForm.email);
    },

    validateCheckbox(rule, value, callback) {
      if (value !== true) {
        callback(new Error(`${this.$store.getters.translations.acceptanceTerms[this.$store.getters.language]}`));
      }
    },

    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      await this.$recaptcha('register');
      // Do stuff with the received token.
    },
    showTerms() {
      this.isTermsVisible = true;
    }
  }
}
</script>
<style>
.RegisterModal__secondary-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.RegisterModal {
  padding: 20px 0;
  position: fixed;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  background-color: #333333;
}

.RegisterModal .RegisterModal__close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.RegisterModal .RegisterModal__register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RegisterModal .RegisterModal__register-form .el-form-item__label {
  color: #fff;
  padding: 0;
}

.RegisterModal .RegisterModal__register-form__terms-checkbox__container {
  width: 100%;
}

.RegisterModal .RegisterModal__register-form__terms-label {
  margin-left: 5px;
}

.RegisterModal .RegisterModal__register-form__terms-label-link {
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
  color: rgb(66, 189, 189);
}

.RegisterModal .RegisterModal__register-form__terms-checkbox__container .el-form-item__content {
  display: flex;
}

.RegisterModal .RegisterModal__register-form .el-form-item {
  width: 100%;
}

.RegisterModal .RegisterModal__register-form .register-button {
  margin-top: 20px;
}

.terms-list {
  text-align: left;
}

@media screen and (max-width: 769px) {
  .RegisterModal__secondary-bg .el-dialog {
    min-width: 90%;
    margin-top: 15vh !important;
  }

  .RegisterModal {
    max-width: 100;
    width: auto;
    padding: 20px 25px;
  }
}
</style>


