<template>
  <div class="LoginForm">
    <h4>{{ $store.getters.translations.createAnAccount[this.$store.getters.language] }}!</h4>
    <p>
      {{ $store.getters.translations.whenYouCreated[this.$store.getters.language] }}
    </p>
    <el-form @keyup.enter="submitForm" :model="loginForm" status-icon :rules="rules" ref="loginForm" label-width="120px" class="LoginForm"
      label-position="top">
      <el-form-item label="Email" prop="email">
        <el-input v-model="loginForm.email"></el-input>
      </el-form-item>

      <el-form-item :label="$store.getters.translations.password[this.$store.getters.language]" prop="password">
        <el-input type="password" v-model="loginForm.password"></el-input>
      </el-form-item>

      <el-form-item v-loading="isLoading" element-loading-spinner="el-icon-loading">
        <el-button size="large" class="login-button" type="primary" @click="submitForm">{{
          $store.getters.translations.signIn[this.$store.getters.language] }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';

export default {
  name: "LoginForm",

  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      rules: {
        email: {
          required: true,
          message: this.$store.getters.translations.enterEmail[this.$store.getters.language],
        },
        password: {
          required: true,
          message: this.$store.getters.translations.enterPassword[this.$store.getters.language],
        },
      },
      isLoading: false,
    }
  },

  methods: {
    async submitForm() {
      this.loginForm.email = this.loginForm.email.trim();
      if (this.validateEmail()) {
        this.isLoading = true;
        const result = await this.$store.dispatch('loginToPlatform', this.loginForm);
        this.isLoading = false;
        if (result) {
          this.$router.push({ name: 'UserFiles' });
        }
      } else {
        ElMessage({
          showClose: true,
          message: this.$store.getters.platformMessages.invalidEmail[this.$store.getters.language],
          type: 'error',
        });
      }
    },
    validateEmail() {
      const rule = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return rule.test(this.loginForm.email);
    }
  }
};
</script>
<style>
.LoginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.LoginForm .el-icon-loading {
  margin-top: 15px;
}

.LoginForm .login-button {
  background-color: rgb(66, 189, 189);
  border: none;
  margin: auto;
}

.LoginForm .login-button:hover {
  background-color: rgb(66, 175, 175);
}

.LoginForm .el-form-item__label {
  color: #fff;
  padding-bottom: 0 !important;
}

.LoginForm h4 {
  font-size: 26px;
  margin-bottom: 0;
  margin-top: 10px;
}

@media screen and (max-width: 769px) {

  .LoginForm .el-form,
  .LoginForm .el-form .el-form-item {
    width: 90%;
  }
}

@media screen and (max-width: 481px) {

  .LoginForm .el-form,
  .LoginForm .el-form .el-form-item {
    width: 100%;
  }
}
</style>


