<template>
  <div class="StartPageLayout">
    <div class="upload-file start-block">
      <h2 class="about_vaglogs">
        {{
          $store.getters.translations.aboutVaglogs[this.$store.getters.language]
        }}
      </h2>
      <UploadFile />
    </div>
    <div class="start-block">
      <LoginForm />
      <el-button size="large" class="register-button" type="primary" @click="openCloseRegistrationModal(true)">
        {{
          $store.getters.translations.haveNotYouAccount[
          this.$store.getters.language
          ]
        }}?
        {{ $store.getters.translations.register[this.$store.getters.language] }}
      </el-button>
      <el-button size="large" class="reset-password-button" type="danger" @click="openClosePasswordResetModal(true)">
        {{
          $store.getters.translations.forgotPassword[
            this.$store.getters.language
          ]
        }}
      </el-button>
    </div>
    <transition-group name="fade">
      <RegisterModal v-if="registerModal" @openCloseModal="openCloseRegistrationModal" />
      <PasswordResetModal v-if="passwordResetModal" @openCloseModal="openClosePasswordResetModal" />
    </transition-group>
  </div>
</template>

<script>
import RegisterModal from '@/components/Common/RegisterModal.vue';
import PasswordResetModal from '@/components/Common/PasswordResetModal.vue';
import LoginForm from '@/components/Common/LoginForm.vue';
import UploadFile from '@/components/Common/UploadFile.vue';
import { mapState } from 'vuex';

export default {
  name: "StartPage",

  components: {
    RegisterModal,
    PasswordResetModal,
    LoginForm,
    UploadFile,
  },

  data() {
    return {
      registerModal: false,
      passwordResetModal: false,
    }
  },

  computed: {
    ...mapState(['user']),
    userName() {
      return this.$store.state.user.name
    },
  },

  methods: {
    openCloseRegistrationModal(isRegistrationModelVisible) {
      this.registerModal = isRegistrationModelVisible;
    },
    openClosePasswordResetModal(isPasswordResetModelVisible) {
      this.passwordResetModal = isPasswordResetModelVisible;
    }
  }
};
</script>
<style scoped>
.StartPageLayout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 7% 4% 7%;
  margin: auto 0;
}

.StartPageLayout .start-block .register-button {
  background-color: rgb(66, 189, 189);
  color: white;
  border: none;
}

.StartPageLayout .start-block .register-button:hover {
  background-color: rgb(66, 175, 175);
}

.StartPageLayout .start-block {
  height: 70%;
  width: 40%;
  padding: 25px;
  background: transparent;
}

.StartPageLayout .start-block .about_vaglogs {
  margin: 0;
  font-size: 18px;
  font-weight: 200;
  text-align: left;
  color: #92ddfb;
}

@media screen and (max-width: 769px) {
  .StartPageLayout {
    flex-wrap: wrap;
    margin-bottom: 50px;
    padding: 4% 7%;
  }

  .StartPageLayout .start-block {
    width: auto;
    padding: 15px 0 0 0;
  }

  .StartPageLayout .start-block .about_vaglogs {
    display: flex;
  }

  .StartPageLayout .reset-password-button {
    margin-top: 15px;
  }

  .StartPageLayout .reset-password-button,
  .StartPageLayout .register-button {
    margin: 0 5px 5px 5px !important;
  }
}

@media only screen and (min-device-width: 780px) and (max-device-width: 1265px) {

  .StartPageLayout .reset-password-button,
  .StartPageLayout .register-button {
    margin: 0 5px 5px 5px !important;
  }
}
</style>


