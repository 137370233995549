<template>
  <div class="FileEditor">
    <div class="content">
      <div class="content__tech_info">
        <div class="content__tech_info__row">
          <span class="content__tech_info__row-label">{{
            $store.getters.translations.engine[this.$store.getters.language]
          }}:
          </span>
          <span class="content__tech_info__row-value">{{ file.engine }}</span>
        </div>

        <div class="content__tech_info__row">
          <span class="content__tech_info__row-label">ECU: </span>
          <span class="content__tech_info__row-value">{{ file.ecu }}</span>
        </div>
        <div class="content__tech_info__row">
          <span class="content__tech_info__row-label">{{
            $store.getters.translations.groups[this.$store.getters.language]
          }}:
          </span>
          <span class="content__tech_info__row-value">{{ file.groups }}</span>
        </div>
      </div>
      <div class="content__description">
        <div class="description-row">
          <span class="description-label">{{
            $store.getters.translations.title[this.$store.getters.language]
          }}:</span>
          <!-- INLINE STYLES -->
          <el-input size="small" style="width: 70%; margin-left: 20px" v-model="title" maxlength="20" :placeholder="$store.getters.translations.enterTitle[
            this.$store.getters.language
          ]
            " />
        </div>
        <div class="description-row">
          <span class="description-label">{{
            $store.getters.translations.comment[this.$store.getters.language]
          }}:</span>
          <!-- INLINE STYLES -->
          <el-input size="small" style="width: 100%" v-model="comment" maxlength="40" :placeholder="$store.getters.translations.enterComment[
            this.$store.getters.language
          ]
            " />
        </div>
        <div class="description-row created_at">
          <span class="description-label">{{
            $store.getters.translations.createdAt[
            this.$store.getters.language
            ]
          }}:</span>
          <span class="description-value">{{ file.created_at }}</span>
        </div>
      </div>
    </div>
    <div class="actions">
      <el-tooltip class="item" effect="light" :content="$store.getters.translations.save[this.$store.getters.language]
        " placement="bottom">
        <el-button class="action-button">
          <font-awesome-icon class="save-icon" :icon="['fas', 'check']" @click="save()" />
        </el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="light" :content="$store.getters.translations.cancel[this.$store.getters.language]
        " placement="bottom">
        <el-button class="action-button">
          <font-awesome-icon class="cancel-icon" :icon="['fas', 'times']" @click="cancel()" />
        </el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';

export default {
  name: 'FileEditor',

  props: {
    file: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      isFileEdit: false,
      title: this.file.title,
      comment: this.file.comment,
      newDataToUpdate: {
        title: this.file.title,
        comment: this.file.comment,
      },
      fileUuid: this.file.file_uuid,
    }
  },

  watch: {
    title(newValue) {
      this.newDataToUpdate.title = newValue;
    },

    comment(newValue) {
      this.newDataToUpdate.comment = newValue;
    }
  },

  methods: {
    async save() {
      const params = {
        title: this.newDataToUpdate.title,
        comment: this.newDataToUpdate.comment,
        file: this.fileUuid,
      }
      await this.$store.dispatch('updateFile', params);
      ElMessage({
        showClose: true,
        message: this.$store.getters.platformMessages.dataUpdated[this.$store.getters.language],
        type: 'success',
      });
      this.$store.dispatch('getFilesList');
      this.$emit('close-editor');
    },

    cancel() {
      this.$emit('close-editor');
    }
  }
}
</script>
<style scoped>
.FileEditor {
  width: 230px;
  height: 270px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  transition: all 0.2s ease-in;
  position: relative;
  background: radial-gradient(92.98% 92.98% at 2.2% 1.86%,
      rgba(101, 101, 101, 0.5) 0%,
      rgba(34, 34, 34, 0) 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  box-shadow: 0px 4px 11px 1px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.FileEditor:hover {
  box-shadow: -20px 20px 30px rgba(255, 255, 255, 0.096);
}

.FileEditor .content {
  height: 90%;
  display: flex;
  padding: 15px;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  line-height: 15px;
}

.FileEditor .content .content__tech_info {
  height: 35%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
  border-bottom: 1px solid rgba(217, 217, 217, 0.25);
}

.FileEditor .content .content__tech_info__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FileEditor .content .content__groups {
  width: 100%;
  height: 40%;
  background: #fff;
  color: #000;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  opacity: .5;
}

.FileEditor .content .content__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 60%;
  color: #fff;
}

.FileEditor .content .content__description .description-row {
  width: 100%;
  margin: 5px 0;
  text-align: left;
}

.FileEditor .content .content__description .description-label,
.FileEditor .content .content__tech_info .content__tech_info__row-label {
  font-weight: 700;
  display: inline-block;
}


.FileEditor .content .content__description .description-value,
.FileEditor .content .content__tech_info .content__tech_info__row-value {
  opacity: .7;
  display: inline-block;
  margin-left: 10px;
}

.FileEditor .actions {
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(172.19deg,
      #346671 0%,
      rgba(34, 34, 34, 0) 210.39%);
  border-radius: 0px 0px 12px 12px;
  padding: 0 15px;
}

.FileEditor .actions .action-button {
  background: transparent;
  border: none;
  font-size: 20px;
  margin: 0;
  cursor: pointer;
}

.FileEditor .actions .cancel-icon {
  color: red;
}

.FileEditor .actions .save-icon {
  color: #fff;
}
</style>
