<template>
  <div class="SimpleFileResults">
    <BackButtonFixed :icon="'arrow-left'" @back="back" />
    <FileInfoSection :file-info="fileInfoForView" />
    <hr />
    <div class="SimpleFileResults__container">
      <table class="SimpleFileResults__table">
        <thead>
          <tr v-for="headerRow in headersArr" :key="headerRow">
            <th v-for="key in headerRow" :key="key">
              {{ key }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="line in results" :key="line">
            <td
              v-for="(key, index) in line"
              :key="key"
              :class="{
                green:
                  engineSpeedIndex.includes(index) && parseFloat(key) < 3000,
                yellow:
                  engineSpeedIndex.includes(index) &&
                  parseFloat(key) >= 3000 &&
                  parseFloat(key) < 4000,
                orange:
                  engineSpeedIndex.includes(index) &&
                  parseFloat(key) >= 4000 &&
                  parseFloat(key) <= 5000,
                red: engineSpeedIndex.includes(index) && parseFloat(key) > 5000,
              }"
            >
              {{ key }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FileInfoSection from './FileInfoSection.vue';
import BackButtonFixed from '@/components/Platform/BackButtonFixed.vue';

export default {
  name: "SimpleFileResults",

  components: {
    FileInfoSection,
    BackButtonFixed,
  },

  props: {
    headersArr: {
      required: true,
      type: Object,
    },
    results: {
      required: true,
      type: Object,
    },
    fileInfo: {
      required: false,
      type: Object,
    },
  },

  data() {
    return {
      engineSpeedIndex: [],
    }
  },

  methods: {
    back() {
      this.$router.push({ name: "UserFiles" });
    }
  },

  computed: {
    fileInfoForView() {
      let fileInfo = {
        dateRow: this.headersArr[0],
        engine: this.headersArr[1][2],
        ecu: this.headersArr[1][0],
      };
      if (this.fileInfo) {
        fileInfo.title = this.fileInfo.title;
        fileInfo.comment = this.fileInfo.comment;
        fileInfo.groups = this.fileInfo.groups;
      }
      return fileInfo;
    },
    isLogged() {
      return this.$store.getters.isLogged;
    }
  },

  created() {
    this.headersArr.forEach(row => {
      row.filter((field, index) => {
        if (field.includes('Engine Speed') || field.includes('Engine speed') || field.includes('engine speed') || field.includes('RPM') || field.includes('rpm') || field.includes('Obroty silnika') || field.includes('obroty silnika') || field.includes('OBROTY SILNIKA') || field.includes('Obroty Silnika') || field.includes('Tr/min') || field.includes('tr/min') || field.includes('TR/MIN')) {
          this.engineSpeedIndex.push(index);
        }
      });
    });
  },

}
</script>
<style scoped>
.SimpleFileResults {
  overflow-x: hidden;
}

.SimpleFileResults hr {
  color: rgba(0,0,0,.5);
  width: 80%;
}

.SimpleFileResults__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3% 10%;
}

.SimpleFileResults__table {
  background-color: #fff;
  font-size: 14px;
  color: #000;
}

table {
  border-collapse: collapse;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 3px 5px;
  font-size: .75em;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tbody tr:hover {
  font-weight: 700;
  transition: all .2s ease-in;
  text-decoration: underline;
}

tbody .orange {
  background-color: rgba(255, 115, 0, 0.1);
}

tbody .red {
  background-color: rgba(255, 0, 0, 0.1);

}

tbody .green {
  background-color: rgba(0, 128, 0, 0.1);
}

tbody .yellow {
  background-color: rgba(255, 255, 0, 0.1);
}

@media screen and (max-width: 1020px) {
  .SimpleFileResults .SimpleFileResults__container {
    overflow: scroll;
    display: block;
    padding: 0;
    margin: 10px 10px 25px 10px;
  }

  .SimpleFileResults .SimpleFileResults__container table {
    font-size: 10px;
  }
}
</style>