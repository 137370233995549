<template>
  <div>
    <router-view />
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@500&family=Poppins:wght@300;600&family=Montserrat:wght@300;500;600;700&display=swap");
* {
  box-sizing: border-box;
}

html {
  min-height: 100vh;
}

body {
  margin: 0;
  min-height: 100vh;
}

#app {
  min-height: 100vh;
  font-family: "Montserrat";
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

.el-message .el-message__content,
.el-message-box {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

/* transition */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media screen and (max-width: 769px) {
  .Error-dialog-box {
    width: 95% !important;
  }
}
</style>
