<template>
  <div class="ExternalFileResultsView">
    <StartPageHeader />
    <div class="ExternalFileResultsViewContainer">
      <div class="ExternalFileResultsView__settings">
        <el-switch
          v-model="isChartViewEnabled"
          class="mb-2"
          active-color="#13ce66"
          inactive-color="#0276FD"
          :active-text="
            $store.getters.translations.showChart[$store.getters.language]
          "
          :inactive-text="
            $store.getters.translations.showTable[$store.getters.language]
          "
        />
      </div>
      <transition name="fade">
        <SimpleFileResults
          v-if="!isChartViewEnabled"
          :headers-arr="headersFromStore"
          :results="otherResultsFromStore"
          :file-info="fileInfo"
        />
        <Chart
          v-else
          :headers-arr="headersFromStore"
          :results="otherResultsFromStore"
          :file-info="fileInfo"
        />
      </transition>
    </div>
    <StartPageFooter />
  </div>
</template>

<script>
import StartPageHeader from "@/components/Platform/StartPageHeader.vue";
import SimpleFileResults from "@/components/Common/SimpleFileResults.vue";
import Chart from "@/components/Common/Chart.vue";
import StartPageFooter from "@/components/Platform/StartPageFooter.vue";

export default {
  name: "LocalExternalFileResultsView",
  components: {
    StartPageHeader,
    SimpleFileResults,
    StartPageFooter,
    Chart,
  },

  data() {
    return {
      isChartViewEnabled: false,
    };
  },

  computed: {
    headersFromStore() {
      return this.$store.getters.fileForView.headersArr;
    },
    otherResultsFromStore() {
      return this.$store.getters.fileForView.otherResults;
    },
    fileInfo() {
      return this.$store.getters.fileForView.fileInfo;
    },
  },

  unmounted() {
    const results = {
      headersArr: [],
      otherResults: [],
    };
    this.$store.commit("viewFileResults", results);
  },
};
</script>

<style scoped>
.ExternalFileResultsView {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-image: url("../assets/background.png");
  background-size: cover;
  background-attachment: fixed;
  font-family: "Poppins", sans-serif;
}

.ExternalFileResultsView .ExternalFileResultsViewContainer {
  background: #fff;
}

.ExternalFileResultsView__settings {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 3px 15px;
  border-bottom: 1px solid rgb(173, 173, 173);
}

@media screen and (max-width: 450px) {
  .ExternalFileResultsView__settings {
    padding-bottom: 10px !important;
  }
}
</style>