<template>
  <div class="FolderEditor">
    <span>{{
      $store.getters.translations.enterFolderName[this.$store.getters.language]
    }}:</span>
    <div class="FolderEditor_input_container">
      <el-input v-model="newFolderName" maxlength="40" :placeholder="$store.getters.translations.enterFolderName[
        this.$store.getters.language
        ]
        " />
    </div>
    <div class="button-container">
      <button class="submit-button" @click="save">
        {{ $store.getters.translations.save[$store.getters.language] }}
      </button>
      <button size="large" class="cancel-button" @click="cancel">
        {{ $store.getters.translations.cancel[this.$store.getters.language] }}
      </button>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';

export default {
  name: 'FolderEditor',

  props: {
    folder: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      newFolderName: this.folder.name,
    }
  },

  watch: {
    name(newValue) {
      this.newDataToUpdate.title = newValue;
    },
  },

  methods: {
    async save() {
      const params = {
        name: this.newFolderName,
        folder: this.folder.folder_uuid,
      }
      await this.$store.dispatch('updateFolder', params);
      ElMessage({
        showClose: true,
        message: this.$store.getters.platformMessages.dataUpdated[this.$store.getters.language],
        type: 'success',
      });
      this.$store.dispatch('getFoldersList');
      this.cancel();
    },

    cancel() {
      this.$emit('toggleFolderEditor');
    }
  }
}
</script>
<style scoped>
.FolderEditor {
  background: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  transition: all .2s ease-in;
  border-radius: 5px;
  margin: 6px 15px;
  cursor: pointer;
}

.FolderEditor_input_container {
  width: 50%;
  margin-left: 20px;
}

.FolderEditor .button-container {
  width: 18%;
  display: flex;
  justify-content: space-evenly;
  margin-left: auto;
}

.FolderEditor .button-container .submit-button {
  background: rgba(0, 168, 168, 1);
  color: #fff;
  padding: 10px 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .1s ease;
  border-radius: 5px;
  margin: 5px 0;
}

.button-container .cancel-button {
  background-color: rgb(223, 0, 0);
  color: #fff;
  padding: 10px 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .1s ease;
  border-radius: 5px;
  margin: 5px 0;
}

.button-container .cancel-button:hover {
  background-color: rgb(255, 8, 8);
  transform: scale(1.05);
}

.button-container .submit-button:hover {
  background-color: rgb(25, 156, 255);
  transform: scale(1.05);
}

@media screen and (max-width: 769px) {
  .FolderEditor {
    flex-wrap: wrap;
  }

  .FolderEditor .FolderEditor_input_container {
    width: 80%;
  }

  .FolderEditor .button-container {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin: auto;
  }
}
</style>