export default {
    invalidFileFormat: {
        pl: 'Nieprawidłowy format pliku',
        eng: 'Invalid file format',
    },
    invalidEmail: {
        pl: 'Adres email jest nieprawidłowy. Sprawdź czy zawiera znak "@"',
        eng: 'Email address is invalid! Check if it contains the "@"',
    },
    dataUpdated: {
        pl: 'Dane zostały zaktualizowane',
        eng: 'The data has been updated',
    },
    fileNotExist: {
        pl: 'Plik nie istnieje',
        eng: 'File does not exist',
    },
    logoutSuccess: {
        pl: 'Wylogowano pomyślnie',
        eng: 'Logged out successfully'
    },
    activationLinkSent: {
        pl: 'Na podany adres email został wysłany link aktywacyjny',
        eng: 'An activation link has been sent to the e-mail address provided',
    },
    passwordResetLinkSent: {
        pl: 'Link do resetowania hasła został wysłany na podany email',
        eng: 'Password reset link was sent',
    },
    accountHasBeenActivated: {
        pl: 'Konto zostało aktywowane',
        eng: 'The account has been activated',
    },
    accountActivationError: {
        pl: 'Błąd aktywacji konta, Twoje konto prawdopodobnie jest już aktywne',
        eng: 'Account activation error, your account is probably already active',
    },
    languageHasBeenChanged: {
        pl: 'Język został zmieniony',
        eng: 'Language has been changed',
    },
    fileIsEmpty: {
        pl: 'Plik jest pusty lub uszkodzony',
        eng: 'File is empty or damaged',
    },
    passwordUpdated: {
        pl: 'Hasło zostało zmienione',
        eng: 'Password has been updated',
    },
    copiedToClipboard: {
        pl: 'Skopiowano do schowka',
        eng: 'Copied to clipboard',
    }
}