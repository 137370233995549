import { createStore } from 'vuex';
import VueCookieNext from '../cookies/config';
import router from '../router';
import { API, addBearerToken, removeBearerToken } from '@/config/API.js';
import translations from '@/store/translations/translations.js';
import platformMessages from '@/store/translations/platformMessages.js';

// Create a new store instance.
export default createStore({
    state() {
        return {
            isListElementLoading: {
                elementIndex: false,
                bool: false,
            },
            isListLoading: false,
            isLogged: false,
            session: '',
            user: {
                name: '',
                email: '',
                id: false,
                uuid: '',
            },
            filesList: [],
            foldersList: [],
            viewFileResults: {
                headersArr: [],
                otherResults: [],
                fileInfo: {},
            },
            csvToPost: {
                title: '',
                body: '',
                comment: '',
                groups: [],
                ecu: '',
                engine: '',
            },
            dataToUpdateCsv: {
                title: '',
                comment: '',
            },
            currentSortOption: 'data',
            currentSortOrderOption: 'desc',
            language: VueCookieNext.getCookie('ServiceLanguage'),
            translations,
            platformMessages,
        }
    },

    getters: {
        isListElementLoading: state => state.isListElementLoading,
        isListLoading: state => state.isListLoading,
        filesList: state => state.filesList,
        foldersList: state => state.foldersList,
        user: state => state.user,
        fileForView: state => state.viewFileResults,
        isLogged: state => state.isLogged,
        csvToPost: state => state.csvToPost,
        currentSortOption: state => state.currentSortOption,
        currentSortOrderOption: state => state.currentSortOrderOption,
        language: state => state.language,
        translations: state => state.translations,
        platformMessages: state => state.platformMessages,
    },

    actions: {

        async sendConfirmationMail(context, params) {
            try {
                return await API.post(process.env.VUE_APP_API_URL + '/api/send-email', params)
            } catch (err) {
                return err;
            }
        },

        async registerToPlatform(context, params) {
            try {
                return await API.post(process.env.VUE_APP_API_URL + '/api/register', params);
            } catch (err) {
                return err;
            }
        },

        async loginToPlatform({ commit }, params) {
            try {
                await API.post(process.env.VUE_APP_API_URL + '/api/login', params)
                    .then(async response => {
                        commit('setSession', response.data.access_token);
                        VueCookieNext.setCookie('Session', response.data.access_token);
                        addBearerToken();
                    });
                return true;
            } catch (err) {
                return false;
            }
        },

        async authUserSession({ commit }) {
            addBearerToken();
            await API.get(process.env.VUE_APP_API_URL + '/api/user')
                .then(response => {
                    commit('fillUserData', response.data);
                    commit('logIn');
                }).catch(error => {
                    removeBearerToken();
                    VueCookieNext.removeCookie('Session');
                    commit('logOut');
                    router.push({ name: 'Home' });
                });
        },

        async resetPassword({ }, email) {
            try {
                return await API.post(process.env.VUE_APP_API_URL + '/api/reset-password', { email })
            } catch (err) {
                console.error(err);
            }
        },

        async updatePassword({ }, params) {
            const validatedParams = {
                email: params.email,
                password: params.password,
                password_confirmation: params.checkPassword,
                token: params.token,
            };
            try {
                return await API.patch(process.env.VUE_APP_API_URL + '/api/change-password', validatedParams)
            } catch (err) {
                console.error(err);
            }
        },

        async getFilesList({ commit, state }) {
            commit('isListLoading', true);
            await API.get(process.env.VUE_APP_API_URL + '/api/files/getList', { params: { user: state.user.uuid, sort_order: state.currentSortOrderOption } })
                .then(response => {
                    commit('fillFilesList', response.data.data);
                });
            commit('isListLoading', false);
        },

        async deleteFile({ commit, dispatch, state }, params) {
            commit('isListElementLoading', { elementIndex: params.index, bool: true });
            await API.delete(process.env.VUE_APP_API_URL + '/api/files/deleteFile', { params: { 'file': params.file_uuid } });
            dispatch('getFilesList');
            commit('isListElementLoading', { bool: false });
        },

        async updateFile({ commit, state }, params) {
            commit('isListElementLoading', { elementIndex: params.index, bool: true });
            if (params.title.trim().length === 0) {
                params.title = '-';
            }
            if (params.comment.trim().length === 0) {
                params.comment = '-'
            }
            await API.patch(process.env.VUE_APP_API_URL + '/api/files/updateFile', { ...params });
            commit('isListElementLoading', { bool: false });
        },

        async sendCSVPost({ state }) {
            const csv = state.csvToPost;
            const user_uuid = state.user.uuid;

            if (csv.title.trim().length === 0) {
                csv.title = '-';
            }
            if (csv.comment.trim().length === 0) {
                csv.comment = '-';
            }
            if (csv.body.trim().length === 0) {
                ElMessage({
                    showClose: true,
                    message: state.platformMessages.fileIsEmpty[this.$store.getters.language],
                    type: 'error',
                    duration: 4000,
                });
            }
            if (csv.groups.trim().length === 0) {
                csv.groups = '-';
            }
            if (csv.ecu.trim().length === 0) {
                csv.ecu = '-';
            }
            if (csv.engine.trim().length === 0) {
                csv.engine = '-';
            }
            const config = {
                'user': user_uuid,
                'title': csv.title,
                'groups': csv.groups,
                'body': csv.body,
                'comment': csv.comment,
                'ecu': csv.ecu,
                'engine': csv.engine,
            };
            await API.post(process.env.VUE_APP_API_URL + '/api/files/addFilePost', config);
        },

        async sortList({ commit, dispatch }, sortOrderVariant) {
            commit('changeSortOrderVariant', sortOrderVariant);
            dispatch('getFilesList');
        },

        async getFoldersList({ commit, state }) {
            await API.get(process.env.VUE_APP_API_URL + '/api/folders/getFoldersList', { params: { sort_order: state.currentSortOrderOption } })
                .then(response => {
                    commit('fillFoldersList', response.data.data);
                });
        },

        async createFolder({ dispatch, state }, folderName) {
            const user = state.user.uuid;
            let name = folderName;

            if (name.trim().length === 0) {
                name = 'Nowy folder';
            }

            const params = {
                'user': user,
                'name': name,
            };
            await API.post(process.env.VUE_APP_API_URL + '/api/folders/createFolder', params);
            dispatch('getFoldersList');
        },

        async deleteFolder({ commit, dispatch, state }, params) {
            commit('isListElementLoading', { elementIndex: params.index, bool: true });
            await API.delete(process.env.VUE_APP_API_URL + '/api/folders/deleteFolder', { params: { folder: params.folder } });
            dispatch('getFoldersList');
            commit('isListElementLoading', { bool: false });
        },

        async updateFolder({ commit, state }, params) {
            const folder = params.folder;
            let name = params.name;
            commit('isListElementLoading', { elementIndex: params.index, bool: true });
            if (name.trim().length === 0) {
                name = 'Nowy folder';
            }
            await API.patch(process.env.VUE_APP_API_URL + '/api/folders/updateFolderName', { name: name, folder: folder });
            commit('isListElementLoading', { bool: false });
        },

        async addFilesToFolder({ dispatch, state }, params) {
            await API.patch(process.env.VUE_APP_API_URL + '/api/files/addFileToFolder', { ...params });
            dispatch('getFilesList');
        },

        async deleteFileFromFolder({ dispatch, state }, params) {
            await API.patch(process.env.VUE_APP_API_URL + '/api/files/deleteFileFromFolder', { ...params });
            dispatch('getFilesList');
        }
    },

    mutations: {
        isListElementLoading(state, isLoadingData) {
            state.isListElementLoading.elementIndex = isLoadingData.elementIndex;
            state.isListElementLoading.bool = isLoadingData.bool;
        },
        isListLoading(state, bool) {
            state.isListLoading = bool;
        },
        setSession(state, token) {
            state.session = token;
        },
        fillUserData(state, userData) {
            state.user.name = userData.name;
            state.user.email = userData.email;
            state.user.id = userData.id;
            state.user.uuid = userData.uuid;
        },
        fillFilesList(state, filesList) {
            state.filesList = filesList;
        },
        fillFoldersList(state, foldersList) {
            state.foldersList = foldersList;
        },
        logIn(state) {
            state.isLogged = true;
        },
        logOut(state) {
            state.isLogged = false;
            state.session = '';
            state.user = {
                name: '',
                email: '',
                id: false,
                uuid: '',
            };
            state.filesList = [];
        },
        viewFileResults(state, results) {
            state.viewFileResults.headersArr = results.headersArr;
            state.viewFileResults.otherResults = results.otherResults;
            state.viewFileResults.fileInfo = results.fileInfo;
        },
        loadCSVToPost(state, csv) {
            state.csvToPost.title = csv.title;
            state.csvToPost.body = csv.body;
            state.csvToPost.comment = csv.comment;
            state.csvToPost.groups = csv.groups
        },
        changeSortOrderVariant(state, sortOrderVariant) {
            state.currentSortOrderOption = sortOrderVariant;
        },
        switchLanguage(state, language) {
            state.language = language;
        },
    }
})