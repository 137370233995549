<template>
  <div class="UserFilesList">
    <div class="UserFilesList__options-bar">
      <Searchbar v-if="!isFileUploading" :files-list="filesList" @filtering-files-on-change="filteringFilesOnChange"
        @clear-filters="clearFilters" />
      <div class="UserFilesList__upload-file_container" v-if="!isAddToFolderModeVisible"
        :class="{ full_width: isFileUploading }">
        <div class="UserFilesList__upload-file">
          <div class="UserFilesList__upload-file_step-1" v-if="!isFileUploading">
            <div class="UserFilesList__upload-file_step-1_header">
              {{
                $store.getters.translations.uploadFiles[
                this.$store.getters.language
                ]
              }}
              <img class="UserFilesList__upload-file_step-1_header-icon" src="@/assets/upload-cloud.svg" />
            </div>
            <label class="UserFilesList__upload-file__label">
              <span>{{
                $store.getters.translations.browse[this.$store.getters.language]
              }}</span>
              <input class="load-file" type="file" id="csv_file" ref="file-input" accept=".csv"
                @change="loadCSV($event)" />
            </label>
          </div>
          <div v-loading="isNewElementLoading" class="UserFilesList__upload-file_step-2" v-else>
            <div>
              <h3>
                {{
                  $store.getters.translations.title[
                    this.$store.getters.language
                  ]
                }}:
              </h3>
              <el-input v-model="csvTitle" maxlength="20" :placeholder="$store.getters.translations.enterTheTitle[
                $store.getters.language
                ]
                " />
            </div>
            <div>
              <h3>
                {{
                  $store.getters.translations.groups[
                  this.$store.getters.language
                  ]
                }}:
              </h3>
              <span class="groups-property">{{
                csvGroups.length > 30 ? "-" : csvGroups
              }}</span>
            </div>
            <div>
              <h3>
                {{
                  $store.getters.translations.comment[
                    this.$store.getters.language
                  ]
                }}:
              </h3>
              <el-input v-model="csvComment" maxlength="40" :placeholder="$store.getters.translations.enterComment[
                $store.getters.language
                ]
                " />
            </div>
            <div class="button-container">
              <button class="submit-button" @click="submitFile()">
                {{ $store.getters.translations.save[$store.getters.language] }}
              </button>
              <button size="large" class="cancel-button" @click="clearUploadingFileData()">
                {{
                  $store.getters.translations.cancel[
                    this.$store.getters.language
                  ]
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="UserFilesList__options-bar__add_to_folder_buttons" v-else>
        <el-button size="large" type="primary" @click="addToFolder" :disabled="checkedFilesToAdd.length === 0">{{
          $store.getters.translations.add[this.$store.getters.language]
        }}</el-button>
        <el-button size="large" class="cancel-button-folder" @click="cancelAddingToFolder()">{{
          $store.getters.translations.cancel[this.$store.getters.language]
        }}</el-button>
      </div>
    </div>
    <div class="UserFilesList__navigation" v-if="!isAddToFolderModeVisible">
      <button class="UserFilesList__navigation-button" :class="{ active: activeViewOption === 'files' }"
        @click="setActiveView('files')">
        {{ $store.getters.translations.allFiles[this.$store.getters.language] }}
      </button>
      <button class="UserFilesList__navigation-button" :class="{ active: activeViewOption === 'folders' }"
        @click="setActiveView('folders')">
        {{ $store.getters.translations.folders[this.$store.getters.language] }}
      </button>
      <Sort v-if="!isFileUploading" :files-list="filesList" />
    </div>
    <span v-if="isAddToFolderModeVisible">
      {{
        $store.getters.translations.selectTheFiles[this.$store.getters.language]
      }}
      <b>{{ selectedFolder.name }}</b>
    </span>
    <div class="UserFilesList__no_files" v-if="filesList.length === 0 && activeViewOption === 'files' && !isListLoading">
      <div class="UserFilesList__no_files__header_container">
        <font-awesome-icon class="UserFilesList__no_files__header_container-icon" :icon="['fas', 'frown']" />
        <h3 class="UserFilesList__no_files__header_container-header">
          {{ $store.getters.translations.noLogs[this.$store.getters.language] }}
        </h3>
      </div>
      <span class="UserFilesList__no_files-info">*{{
        $store.getters.translations.addLogsToView[
          this.$store.getters.language
        ]
      }}</span>
    </div>
    <transition name="slide-fade" mode="out-in">
      <div class="UserFilesList_element_container" :class="{ selectFilesToFolderTheme: isAddToFolderModeVisible }"
        v-loading="isListLoading" element-loading-background="rgba(0, 0, 0, 0)" v-if="activeViewOption === 'files'">
        <transition-group name="slide-fade" mode="out-in">
          <UserListElement v-for="(file, index) in filesListForView" :key="file.file_uuid" :file="file" :index="index"
            :is-add-to-folder-mode="isAddToFolderModeVisible" :selected-folder="selectedFolder"
            :folders-list="foldersList" @check-file="checkFile" />
        </transition-group>
      </div>
      <div class="UserFilesList_folders_container" v-loading="isListLoading" element-loading-background="rgba(0, 0, 0, 0)"
        v-else>
        <div class="UserFilesList_folders_container_add" v-if="!isFolderCreating" @click="toggleCreatingFolder">
          <span class="UserFilesList_folders_container_add_button">+</span>
          <span>{{
            $store.getters.translations.createFolder[
            this.$store.getters.language
            ]
          }}</span>
        </div>
        <div class="UserFilesList_folders_container_add" v-else>
          <span>{{
            $store.getters.translations.enterFolderName[
              this.$store.getters.language
            ]
          }}:</span>
          <div class="UserFilesList_folders_container_add_input_container">
            <el-input v-model="newFolderName" maxlength="40" :placeholder="$store.getters.translations.enterFolderName[
              this.$store.getters.language
              ]
              " />
          </div>
          <div class="button-container">
            <button class="submit-button" @click="createFolder()">
              {{ $store.getters.translations.save[$store.getters.language] }}
            </button>
            <button size="large" class="cancel-button" @click="cancelCreatingFolder()">
              {{
                $store.getters.translations.cancel[this.$store.getters.language]
              }}
            </button>
          </div>
        </div>
        <UserFolders v-for="(folder, index) in foldersList" :key="folder.folder_uuid" :folder="folder" :index="index"
          :files-list="filesList" @toggle-add-to-folder-mode="toggleAddToFolderMode" />
      </div>
    </transition>
    <el-backtop :bottom="50" />
  </div>
</template>

<script>
import UserListElement from './UserListElement/UserListElement.vue';
import UserFolders from './UserFolders/UserFolders.vue';
import Searchbar from '@/components/Common/Searchbar.vue';
import Sort from '@/components/Common/Sort.vue';
import { ElMessage } from 'element-plus';

export default {
  name: 'UserFilesList',

  components: {
    UserListElement,
    UserFolders,
    Searchbar,
    Sort,
  },

  data() {
    return {
      activeViewOption: 'files',
      filesListForView: [],
      isNewElementLoading: false,
      isFileUploading: false,
      isFolderCreating: false,
      isAddToFolderModeVisible: false,
      csvTitle: '',
      csvGroups: '',
      csvComment: '',
      csvBody: '',
      csvEcu: '',
      csvEngine: '',
      newFolderName: '',
      selectedFolder: {},
      checkedFilesToAdd: [],
    }
  },

  watch: {
    filesList(newValue) {
      this.filesListForView = newValue;
    },

    csvTitle(newValue) {
      let csv = this.$store.getters.csvToPost;
      csv['title'] = newValue;
      const params = {
        csv,
      };
      this.$store.commit('loadCSVToPost', params.csv);
    },

    csvComment(newValue) {
      let csv = this.$store.getters.csvToPost;
      csv['comment'] = newValue;
      const params = {
        csv,
      };
      this.$store.commit('loadCSVToPost', params.csv);
    },

    csvGroups(newValue) {
      let csv = this.$store.getters.csvToPost;
      csv['groups'] = newValue;
      const params = {
        csv,
      };
      this.$store.commit('loadCSVToPost', params.csv);
    },

    csvEcu(newValue) {
      let csv = this.$store.getters.csvToPost;
      csv['ecu'] = newValue;
      const params = {
        csv,
      };
      this.$store.commit('loadCSVToPost', params.csv);
    },

    csvEngine(newValue) {
      let csv = this.$store.getters.csvToPost;
      csv['engine'] = newValue;
      const params = {
        csv,
      };
      this.$store.commit('loadCSVToPost', params.csv);
    },
  },

  computed: {
    isListLoading() {
      return this.$store.getters.isListLoading;
    },
    filesList() {
      let filesList = this.$store.getters.filesList;
      filesList.forEach(file => {
        if (file.groups !== '-') {
          file.groups = file.groups.replace(/[^0-9\.]+/g, " "); // eslint-disable-line
        }
      });
      return filesList;
    },
    foldersList() {
      return this.$store.getters.foldersList;
    },
  },

  methods: {
    setActiveView(tab) {
      this.activeViewOption = tab;
    },
    loadCSV(e) {
      if (e.target.files[0].name.substr(-4) == '.csv' || e.target.files[0].name.substr(-4) == '.CSV' || e.target.files[0].name.substr(-4) === '.Csv') {
        if (window.FileReader) {
          let reader = new FileReader();
          this.isFileUploading = true;
          reader.readAsText(e.target.files[0]);

          reader.onload = event => {
            let csv = event.target.result;
            let csvToPost = this.$store.getters.csvToPost;
            csvToPost['body'] = csv;
            const params = {
              csvToPost,
            };
            this.$store.commit('loadCSVToPost', params.csvToPost);
            this.csvJSON(csv);
          };

          reader.onerror = function (evt) {
            if (evt.target.error.name == "NotReadableError") {
              alert("Canno't read file !");
            }
          };
        } else {
          alert('FileReader are not supported in this browser.');
        }
      } else {
        ElMessage({
          showClose: true,
          message: this.$store.getters.platformMessages.invalidFileFormat[this.$store.getters.language],
          type: 'error',
          duration: 3000,
        });
      }
    },

    async csvJSON(csv) {
      let lines = csv.split("\n");
      let results = {
        headersArr: [],
        otherResults: [],
      };

      for (let i = 0; i <= 6; i++) {
        results.headersArr.push(lines[i].split(','));
      }

      lines.forEach((line, i) => {
        if (i > 6) {
          results.otherResults.push(line.split(','));
        }
      });
      results.otherResults.pop();

      if (results.headersArr[1][0] !== '') {
        this.csvEcu = results.headersArr[1][0];
      }

      if (results.headersArr[1][2] !== '') {
        this.csvEngine = results.headersArr[1][2];
      }

      results.headersArr.forEach(value => {
        value.forEach((innerValue, index) => {
          if (innerValue.includes('Grupa') || innerValue.includes('Group')) {
            this.csvGroups += `${value[index + 1].substring(1)} `
          }
        });
      });
    },

    async submitFile() {
      this.isNewElementLoading = true;
      try{
        await this.$store.dispatch('sendCSVPost');
        this.clearUploadingFileData();
        this.$store.dispatch('getFilesList');
      } catch(err){
        console.log(err);
      } finally {
        this.isNewElementLoading = false;
      }
    },

    clearUploadingFileData() {
      this.isFileUploading = false;
      this.csvTitle = '';
      this.csvGroups = '';
      this.csvComment = '';
      this.csvBody = '';
      let csvToPost = this.$store.getters.csvToPost;
      csvToPost['body'] = ''
      const params = {
        csvToPost,
      };
      this.$store.commit('loadCSVToPost', params.csvToPost);
    },

    filteringFilesOnChange(params) {
      let filteredList = [];
      if (params.type === 'title') {
        filteredList = this.filesList.filter(file => {
          return file.title.includes(params.value) || file.title.includes(params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase()) || file.title.includes(params.value.toUpperCase()) || file.title.includes(params.value.toLowerCase());
        });
      } else if (params.type === 'groups') {
        filteredList = this.filesList.filter(file => {
          return file.groups.includes(params.value);
        });
      } else if (params.type === 'ecu') {
        filteredList = this.filesList.filter(file => {
          return file.ecu.includes(params.value);
        });
      } else if (params.type === 'engine') {
        filteredList = this.filesList.filter(file => {
          return file.engine.includes(params.value);
        });
      } else if (params.type === 'groupsAndEcu') {
        filteredList = this.filesList.filter(file => {
          return file.ecu.includes(params.value.ecu) && file.groups.includes(params.value.groups);
        });
      }
      this.filesListForView = filteredList;
    },

    clearFilters() {
      this.filesListForView = this.filesList;
    },

    toggleCreatingFolder() {
      this.isFolderCreating = !this.isFolderCreating;
    },

    createFolder() {
      this.$store.dispatch('createFolder', this.newFolderName);
      this.cancelCreatingFolder();
    },

    cancelCreatingFolder() {
      this.newFolderName = '';
      this.toggleCreatingFolder();
    },
    toggleAddToFolderMode(viewMode, folder) {
      this.selectedFolder = folder;
      this.isAddToFolderModeVisible = !this.isAddToFolderModeVisible;
      this.setActiveView(viewMode)
    },
    checkFile(file, isChecked) {
      let checkedFiles = this.checkedFilesToAdd;
      if (isChecked) {
        checkedFiles.push(file);
        this.checkedFilesToAdd = checkedFiles;
      } else {
        checkedFiles = checkedFiles.filter(checkedFile => {
          if (checkedFile !== file) {
            return file;
          }
        });
        this.checkedFilesToAdd = checkedFiles;
      }
    },
    cancelAddingToFolder() {
      this.toggleAddToFolderMode('folders', {});
      this.checkedFilesToAdd = [];
    },
    async addToFolder() {
      await this.$store.dispatch('addFilesToFolder', { files: this.checkedFilesToAdd, folder: this.selectedFolder.folder });
      this.toggleAddToFolderMode('folders', {});
      this.checkedFilesToAdd = [];
    }
  }
}
</script>
<style scoped>
.UserFilesList .UserFilesList__no_files {
  opacity: .8;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.UserFilesList .UserFilesList__no_files__header_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.UserFilesList .UserFilesList__no_files__header_container-icon {
  font-size: 62px;
  margin-right: 10px;
}

.UserFilesList .UserFilesList__no_files__header_container-header {
  margin: 0 0 10px 0;
  font-size: 48px;

}

.UserFilesList .UserFilesList__no_files-info {
  font-size: 18px;
}

.UserFilesList .selectFilesToFolderTheme {
  background: #000;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.UserFilesList__options-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.UserFilesList__options-bar .UserFilesList__upload-file_container {
  margin-right: auto;
  margin-left: 15%;
  min-width: 165px;
}

.UserFilesList__options-bar .full_width {
  width: 100%;
  margin: 0;
}

.cancel-button,
.cancel-button-folder {
  background-color: rgb(223, 0, 0);
  color: #fff;
  padding: 10px 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .1s ease;
  border-radius: 5px;
  margin: 5px 0;
}

.UserFilesList__options-bar .cancel-button:hover,
.cancel-button-folder:hover {
  background-color: rgb(255, 8, 8);
  transform: scale(1.05);
}

.UserFilesList__options-bar .cancel-button-folder {
  margin-left: 10px !important;
}

.UserFilesList__upload-file .UserFilesList__upload-file_step-1 .UserFilesList__upload-file_step-1_header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #42C7C7;
}

.UserFilesList__upload-file .UserFilesList__upload-file_step-1_header .UserFilesList__upload-file_step-1_header-icon {
  margin-left: 10px;
}

.UserFilesList__upload-file .UserFilesList__upload-file_step-2 {
  border: 1px solid rgb(66, 189, 189);
  margin: 0 auto 30px auto;
  height: 140px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.UserFilesList__upload-file .UserFilesList__upload-file_step-2 .el-input__inner {
  background: transparent;
}

.UserFilesList__upload-file .UserFilesList__upload-file_step-2 .groups-property {
  font-size: 16px;
  display: block;
  margin-top: 30px;
}

.UserFilesList__upload-file .UserFilesList__upload-file_step-2 .button-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.UserFilesList__upload-file .UserFilesList__upload-file__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 154px;
  height: 40px;
  border-radius: 4px;
  background-color: #00A8A8;
  margin: 15px 0 5px 0;
  cursor: pointer;
  transition: all .2s ease-out;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

.UserFilesList__upload-file .UserFilesList__upload-file__label:hover {
  background-color: #35DBDB;
  color: #fff;
}

.UserFilesList__upload-file .UserFilesList__upload-file__label .upload-file-icon {
  font-size: 24px;
  color: #fff;
}

.UserFilesList__upload-file .UserFilesList__upload-file__label input[type="file"] {
  display: none;
}

.UserFilesList__navigation {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(217, 217, 217, 0.25);
}

.UserFilesList__navigation .UserFilesList__navigation-button {
  background: rgba(66, 199, 199, 0.25);
  color: #fff;
  width: 112px;
  height: 40px;
  border: none;
  outline: none;
  margin-right: 10px;
  cursor: pointer;
  transition: all .2s ease-in;
  border-radius: 4px 4px 0px 0px;
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  font-family: "Montserrat";
}

.UserFilesList__navigation .UserFilesList__navigation-button:hover {
  background: rgba(255, 255, 255, .2);
}

.UserFilesList__navigation .active {
  background: #00A8A8 !important;
  font-size: 16px;
}

.UserFilesList_element_container {
  min-height: 50vh;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  row-gap: 24px;
  column-gap: 32px;
  padding: 23px 0;
}

.UserFilesList_folders_container_add {
  border: 1px solid #fff;
  background: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  transition: all .2s ease-in;
  border-radius: 5px;
  margin: 15px 0;
  padding: 5px 15px;
  cursor: pointer;
}

.UserFilesList_folders_container_add_input_container {
  width: 50%;
  margin-left: 20px;
}

.UserFilesList_folders_container_add .button-container {
  width: 18%;
  display: flex;
  justify-content: space-evenly;
  margin-left: auto;
}

.UserFilesList_folders_container_add:hover {
  color: #fff;
}

.UserFilesList_folders_container_add:hover .UserFilesList_folders_container_add_button {
  transform: rotate(90deg);
}

.UserFilesList_folders_container_add_button {
  margin-right: 15px;
  font-size: 28px;
  transition: all .2s ease-in;
}

.button-container .submit-button {
  background-color: rgb(0, 168, 168);
  color: #fff;
  padding: 10px 20px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .1s ease;
  border-radius: 5px;
  margin: 5px 0;
}

.button-container .submit-button:hover {
  background-color: rgb(25, 156, 255);
  transform: scale(1.05);
}

@media screen and (max-width: 700px) {
  .UserFilesList__navigation {
    flex-wrap: wrap;
  }
}


@media screen and (max-width: 769px) {
  .UserFilesList {
    padding: 20px;
  }

  .UserFilesList .selectFilesToFolderTheme {
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
  }

  .UserFilesList .UserFilesList__options-bar {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .UserFilesList .UserFilesList__options-bar .UserFilesList__upload-file_container {
    order: -1;
    margin: auto;
    margin-bottom: 20px;
  }

  .UserFilesList_element_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .UserFilesList .UserFilesList__options-bar .UserFilesList__options-bar__add_to_folder_buttons {
    margin: auto;
    margin-top: 15px;
    order: 3;
  }

  .UserFilesList__upload-file .UserFilesList__upload-file_step-2 {
    height: auto;
    flex-direction: column;
    border-right: 0;
    border-left: 0;
  }
}
</style>