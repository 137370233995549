<template>
  <div class="Chart">
    <BackButtonFixed :icon="'arrow-left'" @back="back" />
    <FileInfoSection :file-info="fileInfoForView" />
    <hr />
    <div class="Chart__settings">
      <div>
        <label class="Chart__settings_label">{{ $store.getters.translations.selectChartModel[$store.getters.language]
        }}:</label>
        <el-switch v-model="isPrimaryChartVisible" class="mb-2" active-color="#13ce66" inactive-color="#ff4949"
          :active-text="$store.getters.translations.primary[$store.getters.language]"
          :inactive-text="$store.getters.translations.secondary[$store.getters.language]" />
      </div>
    </div>
    <div v-if="isPrimaryChartVisible">
      <div class="Chart__container">
        <apexchart width="100%" height="100%" type="line" :options="!isSecondAxisVisible ? singleYAxisOptions : multipleYAxisOptions
          " :series="series"></apexchart>
      </div>
    </div>
    <div v-else>
      <SecondaryChart :headers-arr="headersArr" :results="results" />
    </div>
  </div>
</template>

<script>
import { LineChart } from 'vue-chart-3';
import FileInfoSection from './FileInfoSection.vue';
import BackButtonFixed from '@/components/Platform/BackButtonFixed.vue';
import { ElMessageBox } from 'element-plus';
import SecondaryChart from '@/components/Common/SecondaryChart.vue';

export default {
  name: "Chart",

  components: {
    LineChart,
    FileInfoSection,
    BackButtonFixed,
    SecondaryChart,
  },

  props: {
    headersArr: {
      required: true,
      type: Object,
    },
    results: {
      required: true,
      type: Object,
    },
    fileInfo: {
      required: false,
      type: Object,
    },
  },

  data() {
    return {
      isPrimaryChartVisible: true,
      isSecondAxisVisible: false,
      axisLimit: 100,
      allBiggestValues: [],
      allSmallestValues: [],
      biggestArraysDataForFirstAxis: [],
      biggestArraysDataForSecondAxis: [],
      smallestArraysDataForSecondAxis: [],
      optionsTemplate: {
        chart: {
          id: 'Log-chart',
        },
        xaxis: {
          categories: [],
          title: {
            text: 'RPM',
          },
        },
        yaxis: [],
      },
      singleYAxisOptions: {},
      multipleYAxisOptions: {},
      series: [],
    }
  },

  created() {
    this.viewMessageTipBox();
    this.setXAxisCategories();
    this.createAllBiggestValuesData();
    this.assingSeriesToAxisByAxisCount();
  },

  computed: {
    fileInfoForView() {
      let fileInfo = {
        dateRow: this.headersArr[0],
        engine: this.headersArr[1][2],
        ecu: this.headersArr[1][0],
      };
      if (this.fileInfo) {
        fileInfo.title = this.fileInfo.title;
        fileInfo.comment = this.fileInfo.comment;
        fileInfo.groups = this.fileInfo.groups;
      }
      return fileInfo;
    },
  },

  methods: {
    setXAxisCategories() {
      let rpmColumnIndex = [];
      this.headersArr.forEach(row => {
        row.forEach((field, rpmIndex) => {
          if (field.includes('Engine Speed') || field.includes('Engine speed') || field.includes('engine speed') || field.includes('RPM') || field.includes('rpm') || field.includes('Obroty silnika') || field.includes('obroty silnika') || field.includes('OBROTY SILNIKA') || field.includes('Obroty Silnika') || field.includes('Tr/min') || field.includes('tr/min') || field.includes('TR/MIN')) {
            rpmColumnIndex = rpmIndex;
          }
        });
      });
      this.results.forEach(row => {
        this.optionsTemplate.xaxis.categories.push(row[rpmColumnIndex] + ' RPM');
      });
    },
    createAllBiggestValuesData() {
      this.headersArr[4].forEach((groupName, indexOfGroup) => {
        if (groupName !== '' && groupName !== 'Engine Speed' && groupName !== 'Engine speed' && groupName !== 'engine speed' && groupName !== 'RPM' && groupName !== 'rpm' && groupName !== 'Rpm' && groupName !== 'Obroty silnika' && groupName !== 'obroty silnika' && groupName !== 'OBROTY SILNIKA' && groupName !== 'Obroty Silnika' && groupName !== 'Tr/min' && groupName !== 'tr/min' && groupName !== 'TR/MIN') {
          this.allBiggestValues.push(Math.max.apply(null, this.pushResultsValuesToArray(indexOfGroup)));
          this.allSmallestValues.push(Math.min.apply(null, this.pushResultsValuesToArray(indexOfGroup)));
        }
      });
      this.biggestArraysDataForFirstAxis = Math.max.apply(null, this.allBiggestValues.filter(value => value < this.axisLimit));
      this.biggestArraysDataForSecondAxis = Math.max.apply(null, this.allBiggestValues);
      this.smallestArraysDataForSecondAxis = Math.min.apply(null, this.allSmallestValues);
    },
    assingSeriesToAxisByAxisCount() {
      let yAxisArray = [];
      let dataNameForFirstAxis = '';
      let dataNameForSecondAxis = '';
      this.headersArr[4].forEach((groupName, indexOfGroup) => {
        if (groupName !== '' && groupName !== 'Engine Speed' && groupName !== 'Engine speed' && groupName !== 'engine speed' && groupName !== 'RPM' && groupName !== 'rpm' && groupName !== 'Rpm' && groupName !== 'Obroty silnika' && groupName !== 'obroty silnika' && groupName !== 'OBROTY SILNIKA' && groupName !== 'Obroty Silnika' && groupName !== 'Tr/min' && groupName !== 'tr/min' && groupName !== 'TR/MIN') {
          const decsriptionName = this.headersArr[5][indexOfGroup];
          const isBoostLabelExist = decsriptionName.includes('spec') || decsriptionName.includes('act') || decsriptionName.includes('Spec') || decsriptionName.includes('Act');
          const units = this.headersArr[6][indexOfGroup]

          const name = isBoostLabelExist ? `${groupName} - ${decsriptionName}  (${units})` : `${groupName}  (${units})`;
          const data = this.pushResultsValuesToArray(indexOfGroup);
          const biggestValue = Math.max.apply(null, data);
          const smallestValue = Math.min.apply(null, data);

          this.series.push({ name: name, data: data });

          let yAxisElement = {
            show: false,
          }
          if (biggestValue === this.biggestArraysDataForFirstAxis) {
            yAxisElement = { show: true, min: 0, max: this.axisLimit, name: name }
            dataNameForFirstAxis = name;
          } else if (biggestValue === this.biggestArraysDataForSecondAxis && smallestValue >= this.axisLimit + 1) {
            yAxisElement = { show: true, min: this.axisLimit + 1, opposite: true, name: name }
            dataNameForSecondAxis = name;
          } else if (biggestValue < this.biggestArraysDataForFirstAxis) {
            yAxisElement = { show: false, min: 0, max: this.axisLimit, name: dataNameForFirstAxis }
          } else if (biggestValue > this.biggestArraysDataForFirstAxis && smallestValue >= this.axisLimit + 1) {
            yAxisElement = { show: false, min: this.axisLimit + 1, opposite: true, name: dataNameForSecondAxis }
          } else if (biggestValue > this.biggestArraysDataForFirstAxis && smallestValue <= this.axisLimit + 1) {
            yAxisArray.forEach(yAxis => {
              if (yAxis.show && yAxis.max === this.axisLimit) {
                yAxis.show = false;
              }
            });
            this.axisLimit = biggestValue;
            yAxisElement = { show: false, min: 0, max: this.axisLimit + 1, name: name }//show: false - wiele osi Y
          }
          yAxisArray.push(yAxisElement);
          this.multipleYAxisOptions = { ...this.optionsTemplate, yaxis: yAxisArray };
          this.singleYAxisOptions = { ...this.optionsTemplate, yaxis: {} }
        }
      });
    },
    pushResultsValuesToArray(indexOfGroup) {
      let data = [];
      this.results.forEach(row => {
        if (row[indexOfGroup] !== '') {
          data.push(parseFloat(row[indexOfGroup]));
        }
      });
      return data;
    },

    back() {
      this.$emit('closeView');
      this.$router.push({ name: "UserFiles" });
    },
    viewMessageTipBox() {
      if (!this.$cookie.isCookieAvailable("ChartTipBox")) {
        ElMessageBox.alert(`<p>${this.$store.getters.translations.hideValuesOnChart[this.$store.getters.language]}:</p><img src="https://vaglogs.com/img/tip.gif" style="width: 100%; margin-top: 10px;"/>`, `${this.$store.getters.translations.hint[this.$store.getters.language]}`, {
          confirmButtonText: 'Ok',
          dangerouslyUseHTMLString: true,
          customClass: 'Chart-tip-box',
          callback: () => {
            this.closeTipBox();
          },
        });
      }
    },
    closeTipBox() {
      this.$cookie.setCookie("ChartTipBox", false);
    },
  },
}
</script>
<style scoped>
.Chart {
  color: #000;
}

.Chart hr {
  width: 50%;
  color: rgba(0, 0, 0, .5);
}

.Chart .Chart__container {
  width: 100%;
  max-height: 100vh;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
}

/* PRIMARY CHART STYLE */
.Chart .Chart__container .vue-apexcharts {
  width: 100%;
  padding: 5%;
  height: 100%;
}

/* PRIMARY CHART STYLE */

.Chart .Chart__settings {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 10%;
}

.Chart .Chart__settings .Chart__settings_label {
  margin-right: 10px;
  font-weight: 700;
}

/* SECONDARY CHART STYLES */
.Chart .chart-container {
  padding: 1% 5% 5% 5%;
}

.Chart .chart-container canvas {
  min-height: 600px !important;
  max-height: 600px !important;
  display: block !important;
  touch-action: none !important;
  user-select: none !important;
}

/* SECONDARY CHART STYLES */

.Chart-tip-box {
  width: 40% !important;
}

.Chart-tip-box .el-message-box__title span {
  font-weight: 700;
}

.Chart .Chart__container .vue-apexcharts {
  max-width: 100%;
}

@media screen and (max-width: 769px) {
  .Chart-tip-box {
    width: 95% !important;
  }

  .Chart .Chart__container .vue-apexcharts {
    padding: 3%;
  }
}

@media screen and (max-width: 680px) {

  .Chart .Chart__settings {
    justify-content: center;
  }

  .Chart .Chart__settings div {
    margin-bottom: 10px;
  }
}
</style>