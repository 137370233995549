import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import VueCookieNext from './cookies/config.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faInfoCircle, faArrowLeft, faCheck, faExclamationTriangle, faSortAlphaUp, faFolderOpen, faChevronDown, faMinusCircle, faBars, faFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { Chart, registerables } from 'chart.js';
import VueApexCharts from "vue3-apexcharts";

Chart.register(...registerables);
library.add(faTimes, faInfoCircle, faArrowLeft, faCheck, faExclamationTriangle, faSortAlphaUp, faFolderOpen, faChevronDown, faMinusCircle, faBars, faFrown);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCookieNext);
app.use(ElementPlus);
app.use(VueApexCharts);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');