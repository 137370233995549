<template>
  <div class="UserFiles">
    <StartPageHeader />
    <div class="FilesListContainer">
      <UserFilesList />
    </div>
    <StartPageFooter />
  </div>
</template>

<script>
import StartPageHeader from "@/components/Platform/StartPageHeader.vue";
import UserFilesList from "@/components/Common/UserFilesList/UserFilesList.vue";
import StartPageFooter from "@/components/Platform/StartPageFooter.vue";

export default {
  name: "Home",
  components: {
    StartPageHeader,
    UserFilesList,
    StartPageFooter,
  },

  created(){
    this.$store.dispatch('getFilesList');
    this.$store.dispatch('getFoldersList');
  }
};
</script>

<style>
.UserFiles {
  position: relative;
  background-image: url("../assets/background.png");
  background-attachment: fixed;
  background-size: cover;
}

.FilesListContainer {
  min-height: 78vh;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 0 157px;
}

@media screen and (max-width: 769px) {
  .FilesListContainer {
    min-height: 78vh;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 0 10px;
  }
}

@media screen and (min-width: 770px) and (max-width: 1024px) {
  .FilesListContainer {
    padding: 0 50px;
  }
}
</style>
