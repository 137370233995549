<template>
  <div class="Footer">
    <el-button class="foundation-button" type="success" text @click="foundationDialogVisible = true">Wesprzyj Fundację
      Pomocy Dzieciom z Chorobą Nowotworową</el-button>
    <div class="copyright-container">
      <span class="copyright">Copyright &copy; 2024 Artur Ksybek. All Rights Reserved.</span>
      <span class="contact"><b>Contact us:</b> contact@vaglogs.pl</span>
    </div>
    <el-dialog v-model="foundationDialogVisible" width="70vw">
      <Foundation />
    </el-dialog>
  </div>
</template>

<script>
import Foundation from "@/components/Platform/Foundation.vue";
export default {
  name: "StartPageFooter",

  components: {
    Foundation,
  },

  data() {
    return {
      foundationDialogVisible: false,
    }
  }
};

</script>
<style scoped>
@media screen and (max-width: 410px){
  .foundation-button{
   display: flex !important;
   flex-wrap: wrap;
   max-width: 100% !important;
   margin: 0 !important;
   overflow: hidden;
  }
}
.Footer {
  width: 100%;
  min-height: 5vh;
  background: #2f2f30;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.foundation-button {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  margin-left: 20px;
}

.foundation-button:hover {
  background: transparent !important;
  color: rgb(0, 191, 255);
}

.foundation-button:focus {
  background: transparent !important;
  color: rgb(0, 191, 255);
}

.copyright-container {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
}

.copyright {
  transform: translateX(-50%);
}

@media screen and (max-width: 769px) {
  .Footer {
    flex-wrap: wrap;
    padding: 20px 0;
  }

  .Footer .foundation-button,
  .Footer .copyright-container {
    width: auto;
    display: block;
    margin: auto;
  }

  .Footer .copyright-container .contact {
    display: inline-block;
    width: 100%;
    margin: 2px 0;
  }
}
</style>
